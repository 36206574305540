import { Plugin } from 'vue';

import { openAnyDialog, openConfirm, openDialog } from './openDialog';

declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$dialog: {
			open: typeof openDialog;
			openAny: typeof openAnyDialog;
			confirm: typeof openConfirm;
		};
	}
}

export const dialogPlugin: Plugin = app => {
	app.config.globalProperties.$dialog = {
		open: openDialog,
		openAny: openAnyDialog,
		confirm: openConfirm,
	};
};
