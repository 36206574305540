import { ActionContext, getActions } from '@owlsdepartment/vuex-typed';
import { normalize } from 'normalizr';

import { moduleDecorators } from '@/common/loading';
import { ElbowModel } from '@/store/models/Elbow';
import { ListOf } from '@/store/types';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';

import { ElbowGetters } from './getters';
import { ElbowMutations } from './mutations';
import { elbowList as elbowListShema } from './schemas';
import { ElbowState } from './state';
import { FetchElbowListPayload } from './types';

const { loading } = moduleDecorators('elbow');

type Ctx = ActionContext<ElbowState, ElbowGetters, ElbowMutations>;

class ElbowActions {
	@loading()
	async fetchElbowList({ commit }: Ctx, { prosthesis_id }: FetchElbowListPayload) {
		const response = await api.get(`/elbow/${prosthesis_id}`);

		const name = `prosthesis_${prosthesis_id}`;
		const { entities, result } = normalize<any, { elbows: ListOf<ElbowModel> }>(
			response,
			elbowListShema,
		);

		commit('elbowsFetched', entities.elbows);
		commit('elbowListFetched', { name, data: result });

		return response;
	}
}

const actions = createStoreClassInstance(ElbowActions);
export const { fetchElbowList } = getActions(actions, 'elbow');

export default actions;
