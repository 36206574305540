import { ref, watch } from 'vue';
import { NavigationFailure } from 'vue-router';

const isNavigating = ref(false);

export async function syncNavigate(
	navigation: () => Promise<void | NavigationFailure | undefined>,
) {
	if (!isNavigating.value) {
		isNavigating.value = true;

		const ret = await navigation();

		isNavigating.value = false;

		return ret;
	}

	return new Promise<void | NavigationFailure | undefined>(res => {
		const stopWatch = watch(isNavigating, () => {
			if (!isNavigating.value) {
				stopWatch();
				isNavigating.value = true;

				navigation().then(ret => {
					isNavigating.value = false;

					res(ret);
				});
			}
		});
	});
}
