import { getGetters } from '@owlsdepartment/vuex-typed';

import { Tm3dState } from './state';

const getters = {
	urlByOrderId: (state: Tm3dState) => (id: number): string | undefined => {
		return state.urls[id];
	},
};

export type Tm3dGetters = typeof getters;
export const { urlByOrderId } = getGetters(getters, 'tm3d');
export default getters;
