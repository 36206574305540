import { isNil, omitBy } from 'lodash';
import { FunctionalComponent, h, VNode } from 'vue';

import { addPopupVModel } from '@/common/popup';

import AppToast from './components/AppToast.vue';
import { ManualToast } from './ManualToast';

export interface ToastConfig {
	message?: string;
	defaultSlot?: ToastSlotFunction;
	actionSlot?: ToastSlotFunction;
	timeout?: number;
	noTimeout?: boolean;
}

export type ToastSlotFunction = (props: { close: () => void }) => VNode | VNode[];

const DEFAULT_TIMEOUT = 3000;

export function openToast(config: ToastConfig = {}) {
	const toastComponent: FunctionalComponent = () => {
		return h(
			AppToast as any,
			{
				...addPopupVModel(toast),

				message: config.message ?? '',
				onClose: () => toast.unregister(),
			},
			omitBy(
				{
					default: config.defaultSlot,
					action: config.actionSlot,
				},
				isNil,
			),
		);
	};
	const toast = new ManualToast(toastComponent, { isOpen: true });

	if (!config.noTimeout) {
		setTimeout(() => {
			if (toast.isOpen) toast.close();
		}, config.timeout ?? DEFAULT_TIMEOUT);
	}

	return toast;
}
