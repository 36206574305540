import './registerServiceWorker';
import './styles/main.scss';

import { abilitiesPlugin } from '@casl/vue';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag-next';

import { aclPlugin, appAbility } from '@/common/abilities';
import { loadingStatePlugin } from '@/common/loading';
import { dialogPlugin, toastPlugin } from '@/common/popup-systems';
import * as baseComponents from '@/components/base';
import * as contentPlaceholders from '@/components/placeholders';

import App from './App.vue';
import { globalComponentsPlugin, preloaderPlugin } from './plugins';
import { router } from './router';
import store from './store';

const app = createApp(App);

app.config.unwrapInjectedRef = true;

app
	.use(store)
	.use(router)
	.use(loadingStatePlugin)
	.use(globalComponentsPlugin([baseComponents, contentPlaceholders]))
	.use(dialogPlugin)
	.use(preloaderPlugin)
	.use(toastPlugin)
	.use(abilitiesPlugin, appAbility, { useGlobalProperties: true })
	.use(aclPlugin)
	.use(VueGtag, { property: { id: 'UA-103249441-1' } })
	.mount('#app');
