import { Ref, ref } from 'vue';

export function useElementRefs<Names extends readonly string[]>(
	names: Names,
): Record<Names[number], Ref<HTMLElement | undefined>>;

export function useElementRefs<Names extends readonly string[]>(
	names: Names,
	nullable: true,
): Record<Names[number], Ref<HTMLElement | null>>;

export function useElementRefs<Names extends readonly string[]>(
	names: Names,
	nullable = false,
): Record<Names[number], Ref<HTMLElement | undefined | null>> {
	return names.reduce((acc, name) => {
		acc[name] = ref(nullable ? null : undefined);

		return acc;
	}, {} as Record<string, Ref<HTMLElement | undefined | null>>);
}

useElementRefs(['aaa'] as const, true);
