import { isString } from 'lodash';

import { EMAIL_REGEX } from '@/constants/regex/email';
import { ValidationRule } from '@/mixins/validation/validator';

export const email: ValidationRule = value => {
	// We don't check if it is required
	if (!value) {
		return true;
	}

	const valid = isString(value) && EMAIL_REGEX.test(value);

	return valid || 'Please provide a valid email address';
};
