import { ActionContext, getActions } from '@owlsdepartment/vuex-typed';
import { normalize } from 'normalizr';

import { moduleDecorators } from '@/common/loading';
import { TerminalGlove, TerminalModel } from '@/store/models/Terminal';
import { ListOf } from '@/store/types';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';

import { TerminalGetters } from './getters';
import { TerminalMutations } from './mutations';
import { terminalList as terminalListShema } from './schemas';
import { TerminalState } from './state';
import { FetchTerminalListPayload } from './types';

const { loading } = moduleDecorators('terminal');

type Ctx = ActionContext<TerminalState, TerminalGetters, TerminalMutations>;

class TerminalActions {
	@loading()
	async fetchTerminalList({ commit }: Ctx, { prosthesis_id }: FetchTerminalListPayload) {
		const response: TerminalModel[] = await api.get(`/prosthesis/${prosthesis_id}/terminal`);

		const name = `prosthesis_${prosthesis_id}`;
		const { entities, result } = normalize(response, terminalListShema);

		commit('terminalsFetched', entities as { terminals: ListOf<TerminalModel> });
		commit('terminalListFetched', { name, data: result });

		return response;
	}

	@loading()
	async fetchTerminalGloves({ commit }: Ctx, { terminal_slug }: { terminal_slug: string }) {
		const response: TerminalGlove[] = await api.get(`/terminal/${terminal_slug}/gloves`);

		const name = `gloves_${terminal_slug}`;

		commit('terminalGlovesFetched', { name, data: response });

		return response;
	}
}

const actions = createStoreClassInstance(TerminalActions);
export const { fetchTerminalList, fetchTerminalGloves } = getActions(actions, 'terminal');

export default actions;
