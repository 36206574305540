import { computed, watch } from 'vue';

import { updateUserAbilities } from '@/common/abilities';
import { authGetters } from '@/store/modules/auth';

export function useSyncUserAbilities() {
	const currentUser = computed(authGetters.currentUser);

	watch(
		currentUser,
		() => {
			updateUserAbilities(currentUser.value?.role ?? '');
		},
		{ immediate: true },
	);

	return {
		currentUser,
	};
}
