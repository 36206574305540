import { onBeforeUpdate, ref } from 'vue';

export function useArrayRefs() {
	const refsList = ref<HTMLElement[]>([]);

	onBeforeUpdate(() => {
		refsList.value = [];
	});

	return {
		refsList,

		setListRef: (ref: HTMLElement | null, index: number) => {
			if (ref) {
				refsList.value[index] = ref;
			}
		},
	};
}
