import { NavigationGuard } from 'vue-router';

import { globalActions } from '@/store/modules/global';
import { orderActions, orderGetters } from '@/store/modules/order';

export const preloadOrder: NavigationGuard = async (to, from, next) => {
	let orderGuid = to.params?.orderGuid;

	if (!orderGuid) {
		return next();
	}

	if (Array.isArray(orderGuid)) {
		orderGuid = orderGuid[0];
	}

	if (orderGetters.getOrderByGuid()(orderGuid)) {
		return next();
	}

	try {
		await globalActions.addGlobalLoader(() => orderActions.fetchOrderByGuid(orderGuid as string));
	} catch {
		return next('/404');
	}

	if (!orderGetters.getOrderByGuid()(orderGuid)?.step) {
		return next('/404');
	}

	return next();
};
