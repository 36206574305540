import { ActionContext, getActions } from '@owlsdepartment/vuex-typed';
import { normalize } from 'normalizr';

import { moduleDecorators } from '@/common/loading';
import { NotificationModel } from '@/store/models/Notification';
import { ApiCollection, ListOf } from '@/store/types';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';

import { NotificationGetters } from './getters';
import { NotificationMutations } from './mutations';
import { notificationListSchema } from './schemas';
import { NotificationState } from './state';
import { FetchNotificationListPayload } from './types';

const { loading } = moduleDecorators('notification');

type Ctx = ActionContext<NotificationState, NotificationGetters, NotificationMutations>;

class NotificationActions {
	@loading()
	async fetchNotificationList(
		{ commit }: Ctx,
		{ user_id, status = 'unread' }: FetchNotificationListPayload,
	) {
		const name = `user_${user_id}_status_${status}`;

		const url = `/user/${user_id}/notification/${status}`;
		const response = await api.get<ApiCollection<NotificationModel>>(url);
		const { entities, result } = normalize<any, { notifications: ListOf<NotificationModel> }>(
			response,
			notificationListSchema,
		);

		commit('notificationsFetched', entities);
		commit('notificationListFetched', { name, data: result });

		return response;
	}

	@loading({ presets: { id: true } })
	async markNotificationAsRead(ctx: Ctx, id: string) {
		await api.put(`/notification/${id}/status`, {
			status: 'read',
		});
	}
}

const actions = createStoreClassInstance(NotificationActions);
export const notificationActions = getActions(actions, 'notification');

export default actions;
