/* eslint-disable @typescript-eslint/no-non-null-assertion */
type Resolve<T> = (value: T | PromiseLike<T>) => void;
type Reject = (reason?: any) => void;

export class Task<T = void> extends Promise<T> {
	isResolved: boolean;
	isRejected: boolean;
	isFinished: boolean;

	resolve: Resolve<T>;
	reject: Reject;

	constructor() {
		let resolve: Resolve<T>;
		let reject: Reject;

		super((_resolve, _reject) => {
			resolve = _resolve;
			reject = _reject;
		});

		this.isResolved = false;
		this.isRejected = false;
		this.isFinished = false;

		this.resolve = (...args) => {
			this.isResolved = true;
			this.isFinished = true;

			resolve!(...args);
		};
		this.reject = (...args) => {
			this.isRejected = true;
			this.isFinished = true;

			reject!(...args);
		};
	}

	// eslint-disable-next-line prettier/prettier
	static override get [Symbol.species]() {
		return Promise;
	}

	override get [Symbol.toStringTag]() {
		return 'Task';
	}
}
