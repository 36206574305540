import { orderGetters } from '@/store/modules/order';
import { isOrderRejected } from '@/store/modules/order/types';

export enum StepNavigationMode {
	Default = 'default',
	Preview = 'preview',
	Passive = 'passive',
	Final = 'final',
	Edit = 'edit',
}

export const useOrderViewMode = (
	orderGuid: string,
	defaultMode: StepNavigationMode = StepNavigationMode.Default,
) => {
	const order = orderGetters.getOrderByGuid()(orderGuid);

	if (!order) return defaultMode;

	if (isOrderRejected(order.order_status)) {
		return StepNavigationMode.Edit;
	}

	return defaultMode;
};
