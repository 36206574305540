import { getGetters } from '@owlsdepartment/vuex-typed';

import { SkinGloveModel } from '@/store/models/SkinGlove';

import { SkinGloveState } from './state';

const getters = {
	skinGloveListByName: (state: SkinGloveState) => (name: string): Array<SkinGloveModel> => {
		return state.lists[name]?.map((id: number) => state.skinGloves[id]) ?? [];
	},
	skinGloveListByProsthesisId: (state: SkinGloveState) => (id: number): Array<SkinGloveModel> => {
		return getters.skinGloveListByName(state)(`prosthesis_${id}`);
	},
	skinGloveListDefault: (state: SkinGloveState) => getters.skinGloveListByName(state)('default'),
	getSkinGloveById: (state: SkinGloveState) => (id: number): SkinGloveModel | undefined => {
		return state.skinGloves[id];
	},
};

export type SkinGloveGetters = typeof getters;
export const {
	skinGloveListByName,
	skinGloveListByProsthesisId,
	skinGloveListDefault,
	getSkinGloveById,
} = getGetters(getters, 'skinGlove');
export default getters;
