<template>
	<div class="terms-n-conditions">
		<h2>General</h2>
		<p>
			At Glaze Prosthetics, we respect the privacy rights of our users and recognize the importance
			of protecting your information and those of your patients.
		</p>
		<p>We offer a content management platform that allows specialists to:</p>
		<ul class="list-dots">
			<li>Place orders for upper limb prostheses.</li>
			<li>Explore the current product range.</li>
			<li>Track the placed orders.</li>
		</ul>
		<p>
			The Terms and Conditions set out the terms and conditions for the provision of services via
			the I’AM App, the rules of use, explains how information (including personal data as defined
			under the GDPR) is collected, preserved, used, disclosed, transferred by I’AM App and the
			choices/rights you have regarding your Personal Information and those of your patients.
		</p>

		<h2>General information</h2>
		<ol>
			<li>The Web Application (I’AM App) is made available by the Administrator.</li>
			<li>Before using the Application, the User shall read the Terms of Use.</li>
			<li>
				By using the Web Application, the User declares that:
				<ul class="list-alphabetic padded-top">
					<li>
						has read the Terms and Conditions and accepts all of their provisions and agrees to
						comply with them,
					</li>
					<li>
						has voluntarily entered into the use of the Web Application and the services provided
						through it,
					</li>
					<li>
						agrees to have their personal data processed by the Administrator for the purposes of
						proper performance of the services specified in the Terms of Use,
					</li>
					<li>
						agrees to receive system information, news from the Administrator and information on
						disruptions, changes or technical interruptions in the operation of the Web Application.
					</li>
					<li>
						agrees to be contacted by a Glaze Prosthetics employee in the event of inaccuracies
						relating to orders placed or information provided.
					</li>
				</ul>
			</li>
			<li>
				If you do not accept the Terms and Conditions, you should refrain from using the Web
				Application.
			</li>
			<li>
				The moment the User accepts these Terms and Conditions by reading them and then confirming
				that the User has read the Terms and Conditions by ticking the checkbox "I accept the Terms
				and Conditions", an agreement on the use of the Web Application is concluded between the
				Service Provider and the User.
			</li>
			<li>The agreement to use the Application is concluded for an indefinite period of time.</li>
			<li>
				The User may terminate the agreement for the use of the Application without giving reasons,
				subject to a 30-day notice period. The notice of termination requires documentary form and
				is made by sending it by the User to the Service Provider to the hello@glazeprosthetics.com.
			</li>
			<li>
				The Provider may terminate the agreement for the use of the Web Application in the following
				cases:
				<ul>
					<li>
						in the event of a breach by the User of the provisions set out in the Terms and
						Conditions,
					</li>
					<li>
						in the event that the Service Provider intends to discontinue further offering of the
						Service on the market.
					</li>
				</ul>
			</li>
		</ol>

		<h2>Definitions</h2>
		<p>For the purposes of the Terms and Conditions, the terms used herein shall mean:</p>
		<ol>
			<li>
				Administrator - company Glaze Prosthetics Sp. z o.o., at address Prądnicka 12, 30-002
				Kraków, Polska, NIP: 6772421041, REGON: 367431566, Email: hello@glazeprosthetics.com, Tel:
				+48 505 583 571, hereinafter referred to as Glaze Prosthetics.
			</li>
			<li>
				User - person who is a Professional, having full legal capacity, using the Application and
				the User Account. The User is a representative of the company on behalf of which they can
				purchase and order products included in the Web Application.
			</li>
			<li>
				Account - a place in the Web Application, accessible after logging in by providing a login
				and password, assigned to the User, by means of which the User uses the services of the Web
				Application.
			</li>
			<li>Terms and Conditions - these Terms and Conditions of the I’AM App Web Application.</li>
			<li>
				Web Application – software consisting of a server and a web application, available on the
				Internet in the domain iam-op.com. The application is intended for Specialists and is used
				to use the website within the functionalities intended for Specialists.
			</li>
			<li>Specialist - a person authorised to make prosthetic supplies.</li>
			<li>
				Service - means the service offered by the Service Provider via the Web Application.
			</li>
			<li>
				<span style="white-space: nowrap;">
					Service provider - company Glaze Prosthetics Sp. z o.o.
				</span>
				<span style="white-space: nowrap;">
					, at address Prądnicka 12, 30-002 Kraków, Polska
				</span>
				,
				<span style="white-space: nowrap;">NIP: 6772421041</span>
				,
				<span style="white-space: nowrap;">REGON: 367431566</span>
				,
				<span style="white-space: nowrap;">Email: hello@glazeprosthetics.com</span>
				,
				<span style="white-space: nowrap;">Tel: +48 505 583 571</span>
				, hereinafter referred to as Glaze Prosthetics.
			</li>
		</ol>

		<h2>Changes to the Terms and Conditions</h2>
		<p>
			We can modify this Privacy Policy as necessary. If we change it, we will change the date at
			the top of this Privacy Policy. In the event of significant changes, we will notify you or
			your organization more directly by email. We encourage you to periodically review our Privacy
			Policy to stay informed about our data protection practices and the specific actions you can
			take to contribute to protecting your personal life.
		</p>

		<h2>Collection of information</h2>
		<p>
			We collect information that you provide directly to the contact person who sets up your
			account.
		</p>
		<p>The types of information that we collect from you include:</p>
		<ul class="list-dots">
			<li>your first and last name,</li>
			<li>your email address,</li>
			<li>the name, address, phone number of your employer.</li>
		</ul>
		<p>
			You may refuse to provide information to an employee of Glaze Prosthetics when requested. If
			you refuse to provide information when Glaze Prosthetics requests it in connection with the
			use of its services and to comply with its obligations, you may not be able to use Glaze
			Prosthetics affected services. This situation includes when Glaze Prosthetics asks the user to
			provide Personal Data in order to create an account.
		</p>

		<h2>Data Storage, sharing and communication of information</h2>
		<p>
			We provide services through the Web Application in accordance with applicable data protection
			legislation, including the Regulations of the General Data Protection Regulation (GDPR).
			Company Glaze Prosthetics processes personal data in accordance with applicable data
			protection legislation. Personal data shall be processed solely for the purpose of performing
			a specific service provided through the application and to the extent necessary for its
			performance.
		</p>
		<p>
			When processing personal data, we take special care to protect the interests of the data
			subjects and, in particular, ensure that the data are:
		</p>
		<ul class="list-dots">
			<li>processed in accordance with generally applicable law,</li>
			<li>
				collected for the purposes specified in the Regulations and not subjected to further
				processing incompatible with those purposes,
			</li>
			<li>only persons authorised by Glaze Prosthetics are allowed to process personal data.</li>
		</ul>
		<p>
			We are committed to safeguarding personal data against unauthorised access, illegal
			acquisition, processing in violation of applicable data protection legislation. In order to
			comply with this obligation, appropriate technical and organisational measures are applied, in
			accordance with the requirements set out in the applicable legislation on the protection of
			personal data. Personal data is processed at the business premises located at
			<span style="white-space: nowrap;">Prądnicka 12, 30-002 Kraków.</span>
		</p>

		<h2>Your rights and obligations</h2>
		<p>
			We recognize that your Personal Information is valuable to you, and that is why you have
			choices about how they are used and shared. You can exercise your data protection and privacy
			rights at any time by contacting Glaze Prosthetics at hello@glazeprosthetics.com.
		</p>
		<p>
			You are responsible for protecting the email and password used to access the Account on the
			I’AM App. By agreeing to the Terms and Conditions, you agree not to disclose your password to
			any third party. Glaze Prosthetics will not be liable for any loss or damage arising from the
			non-compliance with the above requirements. You shall inform the Glaze Prosthetics support,
			without undue delay, of any breach of security or unauthorised use of your account.
		</p>
		<p>
			You are obliged to use the Web Application in accordance with applicable law, generally
			accepted moral, social, customary and cultural norms, the provisions of the Terms of Use and
			the rules of social coexistence, including ethics.
		</p>
		<p>
			If you want to disable or delete your account, you can do it by sending an email to
			hello@glazeprosthetics.com.
		</p>

		<h2>Equipment requirements</h2>
		<p>
			Glaze Prosthetics provides the Services through the I’AM App via the Internet. The User may
			use the individual functionalities of the Service via any computer or tablet, in any way
			connected to the Internet.
		</p>
		<p>
			For the correct operation of the Web Application, it is required to have computer equipment
			with up-to-date software that provides access to the Internet and to have an updated web
			browser that ensures the correct display of web pages.
		</p>
		<p>
			In the event that the User encounters technical problems with access to the I’AM App or its
			particular functionalities, they may send a notification to the Glaze Prosthetics at the
			following address: hello@glazeprosthetics.com.
		</p>

		<h2>Contact us</h2>
		<p>
			Contact us at hello@glazeprosthetics.com or by letter at the following address:
			<span style="white-space: nowrap;">
				Glaze Prosthetics Sp. z o.o., Prądnicka 12, 30-002 Kraków, Polska to:
			</span>
		</p>
		<ul class="list-dots">
			<li>ask questions about this Privacy and Data Protection Policy and Legal Notice,</li>
			<li>
				make a complaint or if you have any question about the treatment of your Personal
				Information,
			</li>
			<li>ask questions about privacy or data use,</li>
			<li>report a possible violation of privacy legislation.</li>
		</ul>
		<p>Glaze Prosthetics will answer your request within 7 days.</p>
		<p>
			To contact the Data Protection Officer, please send an e-mail to hello@glazeprosthetics.com.
		</p>
		<p>
			Matters not regulated by these Terms and Conditions shall be governed by the applicable
			provisions of Polish law and any disputes shall be settled by the competent Polish common
			courts.
		</p>
	</div>
</template>

<style lang="scss">
.terms-n-conditions {
	ul,
	ol {
		margin: 0 0 20px 40px;
	}
	li {
		margin-bottom: 10px;
	}
	p {
		margin: 10px 0;
	}

	h1 {
		@include heading-3;
	}
	h2 {
		@include heading-4;
		padding: 1.5rem 0;
	}
}

.contact-info {
	margin-top: 20px;
}

.list-dots {
	list-style-type: disc;
}
.list-alphabetic {
	list-style-type: lower-alpha;
}

.list-ordered {
	list-style-type: decimal;
}

.padded-top {
	padding-top: 1rem;
}
</style>
