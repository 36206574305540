import { defineMutations } from '@owlsdepartment/vuex-typed';
import { isObject } from 'lodash';

import { OrderModel, SimpleOrderModel } from '@/store/models/Order';
import { ListOf, PagedListFetched } from '@/store/types';

import getters from './getters';
import state from './state';
import { OrdersStatusCount, UpdateOrderModel } from './types';

const mutations = defineMutations(state, {
	updateOrder(state, order: UpdateOrderModel) {
		const storedOrder = state.orders[order.guid];

		if (!storedOrder) {
			return;
		}

		const keys = Object.keys(order) as (keyof OrderModel)[];

		for (const key of keys) {
			const value = order[key];

			if (value === undefined) {
				continue;
			}

			if (isObject(value)) {
				(storedOrder as any)[key] = {
					...(storedOrder as any)[key],
					...value,
				};
			} else {
				(storedOrder as any)[key] = value;
			}
		}

		state.orders[order.guid] = storedOrder;
	},

	orderFetched(state, { order }: { order: OrderModel }) {
		state.orders[order.guid] = order;
	},

	ordersFetched(state, { orders }: { orders: ListOf<OrderModel> }) {
		for (const k in orders) {
			state.orders[orders[k].guid] = orders[k];
		}
	},

	orderListFetched(state, { name, data }: PagedListFetched) {
		state.lists[name] = data as any;
	},

	orderListReset(state, { name }: { name: string }) {
		state.lists[name] = [] as any;
	},

	orderDeleted(state, id: number) {
		const guid = Object.values(state.orders).find(order => order.id == id)?.guid;

		if (guid) {
			for (const key in state.lists) {
				state.lists[key].data = state.lists[key].data.filter(orderId => orderId !== guid);
			}

			delete state.orders[guid];
		}
	},

	ordersStatusCountFetched(state, count: OrdersStatusCount) {
		const keys: (keyof OrdersStatusCount)[] = [
			'accepted',
			'archived',
			'draft',
			'placed',
			'rejected',
		];

		for (const key of keys) {
			state.count[key] = count[key] ?? 0;
		}
	},

	simpleOrdersFetched(state, { orders }: { orders: ListOf<SimpleOrderModel> }) {
		for (const k in orders) {
			state.simpleOrders[orders[k].guid] = orders[k];
		}
	},

	simpleOrderListFetched(state, { name, data }: PagedListFetched) {
		state.simpleLists[name] = data as any;
	},

	photoDeleted(state, { id, orderGuid }: { id: number; orderGuid: string }) {
		const { photos } = getters.getOrderByGuid(state)(orderGuid) as OrderModel;

		if (!photos) {
			return;
		}

		const idx = photos.findIndex(photo => photo.id === id);

		delete photos[idx];
	},
});

export type OrderMutations = typeof mutations;
export default mutations;
