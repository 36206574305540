import { AsyncComponentLoader, Component, computed, Ref, shallowRef, watch } from 'vue';
import { useRoute } from 'vue-router';

import { AnyObject } from '@/tools/types';

declare module 'vue-router' {
	interface RouteMeta {
		layout?: Partial<Layout>;
	}
}

export interface LayoutSystemProps {
	defaultLayout: Component | AsyncComponentLoader;
}

export interface Layout {
	component?: Component;
	props: AnyObject;
}

export function useLayoutSystem(props: LayoutSystemProps) {
	const $route = useRoute();
	const layout = shallowRef<Layout>({ props: {} });
	const routeLayout = computed(() => {
		const { props: layoutProps, component } = $route.meta.layout ?? {};

		return {
			props: layoutProps ?? {},
			component: component ?? props.defaultLayout,
		};
	});

	watch(routeLayout, curr => {
		// delaying one route meta change, as it's initial and empty
		layout.value = curr;
	});

	return {
		...useTransitionKeys(layout),

		layout,
	};
}

function useTransitionKeys(layout: Ref<Layout>) {
	const $route = useRoute();
	const layoutsMap = new Map<Component, number>();
	let layoutId = 0;

	return {
		layoutKey: computed(() => {
			const { component } = layout.value;

			if (component && !layoutsMap.has(component)) {
				layoutsMap.set(component, layoutId++);
			}

			return (component && layoutsMap.get(component)) ?? -1;
		}),
		viewKey: computed(() => $route.name as string),
	};
}
