import { isNaN } from 'lodash';
import { RouteLocationNormalizedLoaded } from 'vue-router';

export const resolveRouteId = (prop = 'id', toNumber = false) => ({
	$route,
}: {
	$route: RouteLocationNormalizedLoaded;
}): number | string | null => {
	const paramId = $route?.params[prop];

	if (!paramId) {
		return null;
	}

	const id = Array.isArray(paramId) ? paramId[0] : paramId;

	if (toNumber) {
		if (!isNaN(id) && !isNaN(parseInt(id))) {
			return parseInt(id);
		}
	}

	return id;
};
