import { getMutations } from '@owlsdepartment/vuex-typed';

import { ColorPatternModel } from '@/store/models/ColorPattern';
import { ListFetched, ListOf } from '@/store/types';

import { ColorPatternState } from './state';

const mutations = {
	colorPatternsFetched(
		state: ColorPatternState,
		{ colorPatterns }: { colorPatterns: ListOf<ColorPatternModel> },
	) {
		Object.values(colorPatterns ?? {}).forEach(item => (state.colorPatterns[item.id] = item));
	},

	colorPatternFetched(
		state: ColorPatternState,
		{ colorPattern }: { colorPattern: ColorPatternModel },
	) {
		state.colorPatterns[colorPattern.id] = colorPattern;
	},

	colorPatternListFetched(state: ColorPatternState, { name, data }: ListFetched) {
		state.lists[name] = data;
	},

	colorPatternListReset(state: ColorPatternState, { name }: { name: string }) {
		state.lists[name] = [];
	},
};

export type ColorPatternMutations = typeof mutations;
export const {
	colorPatternsFetched,
	colorPatternFetched,
	colorPatternListFetched,
	colorPatternListReset,
} = getMutations(mutations, 'colorPattern');

export default mutations;
