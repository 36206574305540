import { onUnmounted, toRef, watch } from 'vue';

import { EmitFn } from '@/tools/types';

import { Popup } from './Popup';
import { PopupSystem } from './PopupSystem';

type SystemStatic<P extends Popup> = {
	systemId: string;
	instance: PopupSystem<P>;
};

export function usePopup<P extends Popup>(
	popup: P,
	System: SystemStatic<P>,
	props: { isOpen: boolean },
	emit: EmitFn<['update:isOpen']>,
) {
	System.instance.register(popup);

	onUnmounted(() => {
		System.instance.unregister(popup);
	});

	watch(
		() => props.isOpen,
		() => {
			if (props.isOpen !== popup.isOpen) {
				popup.isOpen = props.isOpen;
			}
		},
	);
	watch(
		() => popup.isOpen,
		() => {
			if (popup.isOpen !== props.isOpen) {
				emit('update:isOpen', popup.isOpen);
			}
		},
	);

	return {
		isOpen: toRef(popup, 'isOpen'),
		teleportId: `#${System.systemId}`,
	};
}
