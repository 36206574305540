export type Comparator = '<' | '<=' | '>' | '>=' | '==' | '!=' | '===';
export type CompareFunction = <T>(a: T, b: T) => boolean;

export const comparatorMap: Record<Comparator, CompareFunction> = {
	'!=': (a, b) => a != b,
	'==': (a, b) => a == b,
	'===': (a, b) => a === b,
	'<': (a, b) => a < b,
	'<=': (a, b) => a <= b,
	'>': (a, b) => a > b,
	'>=': (a, b) => a >= b,
};

export function compare<T>(a: T, comparator: Comparator, b: T): boolean {
	if (!(comparator in comparatorMap)) {
		throw new Error(
			`Invalid "comparator" argument. Received: ${comparator}. Expected one of: ${Object.keys(
				comparatorMap,
			)}`,
		);
	}

	return comparatorMap[comparator](a, b);
}
