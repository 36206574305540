import { ActionContext } from '@owlsdepartment/vuex-typed';
import { normalize } from 'normalizr';

import { moduleDecorators } from '@/common/loading';
import { ProsthesisModel } from '@/store/models/Prosthesis';
import { ProsthesisCategory } from '@/store/models/ProsthesisCategory';
import { ListOf } from '@/store/types';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';

import { ProsthesisGetters } from './getters';
import { ProsthesisMutations } from './mutations';
import {
	prosthesisCategoriesList as prosthesisCategoriesListSchema,
	prosthesisList as prosthesisListShema,
} from './schemas';
import { ProsthesisState } from './state';

const { loading } = moduleDecorators('prosthesis');

type Ctx = ActionContext<ProsthesisState, ProsthesisGetters, ProsthesisMutations>;

class ProsthesisActions {
	@loading()
	async fetchProsthesisList({ commit }: Ctx) {
		const response = await api.get(`/prosthesis`);

		const name = 'default';
		const { entities, result } = normalize(response, prosthesisListShema);

		commit('prosthesisFetched', entities as { prosthesis: ListOf<ProsthesisModel> });
		commit('prosthesisListFetched', { name, data: result });

		return response;
	}

	@loading()
	async fetchProsthesisCategories({ commit }: Ctx) {
		const response = await api.get('/prosthesis-categories');

		const name = 'default';
		const { entities, result } = normalize(response, prosthesisCategoriesListSchema);

		commit(
			'prosthesisCategoriesFetched',
			entities as { prosthesisCategories: ListOf<ProsthesisCategory> },
		);
		commit('prosthesisCategoriesListFetched', { name, data: result });

		return response;
	}
}

export default createStoreClassInstance(ProsthesisActions);
