import { getState } from '@owlsdepartment/vuex-typed';

import { TerminalGlove, TerminalModel } from '@/store/models/Terminal';
import { ListOf } from '@/store/types';

const state = () => ({
	terminals: {} as ListOf<TerminalModel>,
	lists: {} as ListOf<Array<number>>,
	gloves: {} as Record<string, TerminalGlove[]>,
});

export type TerminalState = ReturnType<typeof state>;
export const { terminals, lists } = getState(state, 'terminal');

export default state;
