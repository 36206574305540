import { defineMutations } from '@owlsdepartment/vuex-typed';

import { ProsthesisModel } from '@/store/models/Prosthesis';
import { ProsthesisCategory } from '@/store/models/ProsthesisCategory';
import { ListFetched, ListOf } from '@/store/types';

import state from './state';

const mutations = defineMutations(state, {
	prosthesisFetched(state, { prosthesis }: { prosthesis: ListOf<ProsthesisModel> }) {
		Object.values(prosthesis).forEach(item => (state.prosthesis[item.id] = item));
	},

	prosthesisListFetched(state, { name, data }: ListFetched) {
		state.lists[name] = data;
	},

	prosthesisListReset(state, { name }: { name: string }) {
		state.lists[name] = [];
	},

	prosthesisCategoriesFetched(
		state,
		{ prosthesisCategories }: { prosthesisCategories: ListOf<ProsthesisCategory> },
	) {
		Object.values(prosthesisCategories).forEach(
			item => (state.prosthesisCategories[item.prosthesis_category_id] = item),
		);
	},

	prosthesisCategoriesListFetched(state, { name, data }: ListFetched) {
		state.categoriesLists[name] = data;
	},

	prosthesisCategoriesListReset(state, { name }: { name: string }) {
		state.categoriesLists[name] = [];
	},
});

export type ProsthesisMutations = typeof mutations;
export default mutations;
