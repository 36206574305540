import moment from 'moment';

export function formatDate(formatString: string) {
	return (source?: string) => {
		if (!source) {
			return '';
		}

		return moment(source).format(formatString);
	};
}
