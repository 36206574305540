import { PopupSystem } from '@/common/popup';

let instance: DialogSystem;

export class DialogSystem extends PopupSystem {
	// eslint-disable-next-line prettier/prettier
	static override systemId = 'dialog-system';

	// eslint-disable-next-line prettier/prettier
	static override get instance(): DialogSystem {
		if (!instance) {
			instance = new DialogSystem();
		}

		return instance;
	}
}
