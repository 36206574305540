import { defineComponent, reactive } from 'vue';

export interface StagesControllerData {
	currentStage: number;
	stagesAmount: number;
	silent: boolean;
}

export const stagesControllerKey = Symbol('stagesController');

/**
 * @TODO
 * Should be rewritten to composable
 */

function stagesController(stagesAmount: number, provide = false) {
	const data: StagesControllerData = reactive({
		currentStage: 1,
		stagesAmount,
		silent: false,
	});

	return defineComponent({
		provide() {
			return provide ? { [stagesControllerKey]: data } : {};
		},

		computed: {
			currentStage: {
				get() {
					return data.currentStage;
				},
				set(val: number) {
					data.currentStage = val;
				},
			},

			stagesAmount: {
				get() {
					return data.stagesAmount;
				},
				set(val: number) {
					data.stagesAmount = val;
				},
			},
		},

		beforeMount() {
			data.currentStage = 1;
		},

		methods: {
			/**
			 * @param silent prevents animation
			 * @returns `true` if stage was changed
			 */
			setCurrentStage(newStage: number, silent = false): boolean {
				if (newStage < 1 || newStage > this.stagesAmount) {
					return false;
				}

				data.silent = silent;
				this.currentStage = newStage;

				this.$nextTick().then(() => {
					data.silent = false;
				});

				return true;
			},
			/**
			 * @returns `true` if stage was changed
			 */
			nextStage(): boolean {
				if (this.currentStage === this.stagesAmount) {
					return false;
				}

				this.currentStage++;

				return true;
			},
			/**
			 * @returns `true` if stage was changed
			 */
			previousStage(): boolean {
				if (this.currentStage === 1) {
					return false;
				}

				this.currentStage--;

				return true;
			},
		},
	});
}

export default stagesController;
