import { getMutations } from '@owlsdepartment/vuex-typed';

import { TerminalGlove, TerminalModel } from '@/store/models/Terminal';
import { ListFetched, ListOf } from '@/store/types';

import { TerminalState } from './state';

const mutations = {
	terminalsFetched(state: TerminalState, { terminals = {} }: { terminals: ListOf<TerminalModel> }) {
		Object.values(terminals).forEach(item => (state.terminals[item.id] = item));
	},

	terminalListFetched(state: TerminalState, { name, data }: ListFetched) {
		state.lists[name] = data;
	},

	terminalListReset(state: TerminalState, { name }: { name: string }) {
		state.lists[name] = [];
	},
	terminalGlovesFetched(
		state: TerminalState,
		{ name, data }: { name: string; data: TerminalGlove[] },
	) {
		state.gloves[name] = data;
	},
};

export type TerminalMutations = typeof mutations;
export const {
	terminalsFetched,
	terminalListFetched,
	terminalListReset,
	terminalGlovesFetched,
} = getMutations(mutations, 'terminal');

export default mutations;
