import { schema } from 'normalizr';

export const prosthesis = new schema.Entity('prosthesis');
export const prosthesisList = new schema.Array(prosthesis);

export const prosthesisCategories = new schema.Entity(
	'prosthesisCategories',
	{},
	{
		idAttribute: 'prosthesis_category_id',
	},
);
export const prosthesisCategoriesList = new schema.Array(prosthesisCategories);
