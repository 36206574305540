import { ProsthesisModel } from '@/store/models/Prosthesis';
import { ProsthesisCategory } from '@/store/models/ProsthesisCategory';
import { ListOf } from '@/store/types';

const state = () => ({
	prosthesis: {} as ListOf<ProsthesisModel>,
	lists: {} as ListOf<Array<number>>,

	prosthesisCategories: {} as ListOf<ProsthesisCategory>,
	categoriesLists: {} as ListOf<Array<number>>,
});

export type ProsthesisState = ReturnType<typeof state>;
export default state;
