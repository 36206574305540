import { getGetters } from '@owlsdepartment/vuex-typed';

import { TerminalModel } from '@/store/models/Terminal';

import { TerminalState } from './state';

const getters = {
	terminalListByName: (state: TerminalState) => (name: string): Array<TerminalModel> => {
		return state.lists[name]?.map((id: number) => state.terminals[id]) ?? [];
	},
	terminalListByProsthesisId: (state: TerminalState) => (id: number): Array<TerminalModel> => {
		return getters.terminalListByName(state)(`prosthesis_${id}`);
	},
	terminalListDefault: (state: TerminalState) => getters.terminalListByName(state)('default'),
	getTerminalById: (state: TerminalState) => (id: number): TerminalModel | undefined => {
		return state.terminals[id];
	},
	getTerminalGloves: (state: TerminalState) => (slug: string) => state.gloves[`gloves_${slug}`],
};

export type TerminalGetters = typeof getters;
export const {
	terminalListByName,
	terminalListByProsthesisId,
	terminalListDefault,
	getTerminalById,
	getTerminalGloves,
} = getGetters(getters, 'terminal');
export default getters;
