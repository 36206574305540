import { defineComponent } from 'vue';

import { authGetters } from '@/store/modules/auth';
import { prosthesisActions, prosthesisGetters } from '@/store/modules/prosthesis';

export default defineComponent({
	computed: {
		...authGetters.map(['isAuthorized']),
		...prosthesisGetters.map(['prosthesisCategoriesListDefault', 'prosthesisListDefault']),
	},

	watch: {
		isAuthorized() {
			if (this.isAuthorized) {
				this.fetchCategoriesIfNeeded();
				this.fetchProsthesesIfNeeded();
			}
		},
	},

	mounted() {
		this.fetchCategoriesIfNeeded();
		this.fetchProsthesesIfNeeded();
	},

	methods: {
		fetchCategoriesIfNeeded() {
			if (!this.prosthesisCategoriesListDefault.length && this.isAuthorized) {
				prosthesisActions.fetchProsthesisCategories();
			}
		},

		fetchProsthesesIfNeeded() {
			if (!this.prosthesisListDefault.length && this.isAuthorized) {
				prosthesisActions.fetchProsthesisList();
			}
		},
	},
});
