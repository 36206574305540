import { AnyObject, PickKeys } from '@/tools/types';

export function flagsToCssClasses<
	Prefix extends string,
	Base extends AnyObject,
	Flag extends Extract<PickKeys<Base, boolean>, string>,
>(prefix: Prefix, object: Base, flags: Flag[]) {
	// eslint-disable-next-line prettier/prettier
	type ClassName = `${Prefix}--is-${Flag}`
	const cssClass: Partial<Record<ClassName, boolean>> = {};

	for (const flag of flags) {
		const key = `${prefix}--is-${flag}` as const;

		cssClass[key] = object[flag] as any as boolean;
	}

	return cssClass as { [key in ClassName]: boolean };
}
