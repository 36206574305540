import { computed, ComputedRef, onUnmounted, ref } from 'vue';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'hd';
export type Limit = 'min' | 'max';

export interface MediaFeatures {
	type?: 'all' | 'print' | 'screen' | 'speech';
	width?: Size[];
	height?: Size[];
	orientation?: 'landscape' | 'portrait';
}

export type Size = [Limit, Breakpoint];

export const breakpoints: Record<Breakpoint, number> = {
	xs: 320,
	sm: 480,
	md: 768,
	lg: 1024,
	xl: 1200,
	hd: 1920,
};

function translateToQueryString({
	type,
	orientation,
	width = [],
	height = [],
}: MediaFeatures): string {
	const query: string[] = [];

	query.push(type ?? 'all');

	if (orientation) {
		query.push(`(orientation: ${orientation})`);
	}

	width.forEach(([limit, bp]) => query.push(`(${limit}-width: ${breakpoints[bp]}px)`));
	height.forEach(([limit, bp]) => query.push(`(${limit}-height: ${breakpoints[bp]}px)`));

	return query.join(' and ');
}

export function useMediaQuery(medias: MediaFeatures[] = []): ComputedRef<boolean> {
	const query = medias.map(m => translateToQueryString(m)).join(', ');
	const matcher = matchMedia(query);
	const matches = ref(matcher.matches);

	const listener = () => {
		matches.value = matcher.matches;
	};

	matcher.addEventListener('change', listener);
	onUnmounted(() => {
		matcher.removeEventListener('change', listener);
	});

	return computed(() => matches.value);
}
