import { LOADING_MODULE_KEY, loadingModule } from '@/common/loading';

import address from './address';
import auth from './auth';
import colorPattern from './color-pattern';
import elbow from './elbow';
import global from './global';
import notification from './notification';
import order from './order';
import organisation from './organisation';
import prosthesis from './prosthesis';
import skinGlove from './skin-glove';
import socket from './socket';
import terminal from './terminal';
import tm3d from './tm3d';
import user from './user';

export default {
	[LOADING_MODULE_KEY]: loadingModule,

	address,
	auth,
	colorPattern,
	elbow,
	global,
	notification,
	order,
	organisation,
	prosthesis,
	skinGlove,
	user,
	terminal,
	socket,
	tm3d,
};
