import { getState } from '@owlsdepartment/vuex-typed';

import { NotificationModel } from '@/store/models/Notification';
import { ListOf } from '@/store/types';

const state = () => ({
	notifications: {} as ListOf<NotificationModel>,
	lists: {} as ListOf<Array<string>>,
});

export type NotificationState = ReturnType<typeof state>;
export const notificationState = getState(state, 'notification');

export default state;
