import { camelCase, isEqual, isFunction, upperFirst } from 'lodash';
import StickySidebar from 'sticky-sidebar-v2';
import { Directive, VNode } from 'vue';

import { AnyObject } from '@/tools/types';

/**
 * Port of Vue 2 library `VueStickyDirective`
 *
 * https://github.com/renatodeleao/vue-sticky-directive
 */

interface ElementWithSickySidebar extends Element {
	_stickySidebar?: StickySidebar;
}

// acccess StickySidebar Instance via vnode.elm._stickySidebar
const NS = '_stickySidebar';
const DEFAULTS = {
	topSpacing: 0,
	bottomSpacing: 0,
	containerSelector: false,
	innerWrapperSelector: '[data-v-sticky-inner]',
	resizeSensor: true,
	stickyClass: 'is-affixed',
	minWidth: 0,
};

const EVENT_NAMES_MAP = {
	'affix.top.stickySidebar': 'affix-top',
	'affixed.top.stickySidebar': 'affixed-top',
	'affix.bottom.stickySidebar': 'affix-bottom',
	'affixed.bottom.stickySidebar': 'affixed-bottom',
	'affix.container-bottom.stickySidebar': 'affix-container-bottom',
	'affixed.container-bottom.stickySidebar': 'affixed-container-bottom',
	'affix.unbottom.stickySidebar': 'affix-unbottom',
	'affixed.unbottom.stickySidebar': 'affixed-unbottom',
	'affix.static.stickySidebar': 'affix-static',
	'affixed.static.stickySidebar': 'affixed-static',
};

/**
 * Emit
 *
 * @desc Mimic vnode.context.$emit(event, data) https://stackoverflow.com/a/40720172/2801012
 */
const emit = (vnode: VNode, name: string, data: AnyObject) => {
	const handlerName = `on${upperFirst(camelCase(name))}`;
	const handler = vnode.props?.[handlerName];

	if (handler && isFunction(handler)) {
		handler(data);
	}
};

/**
 * Handling eventlistener
 */
const handleEvents = (e: Event, vnode: VNode) => {
	const humanizedEventName = EVENT_NAMES_MAP[e.type as keyof typeof EVENT_NAMES_MAP];

	emit(vnode, humanizedEventName, { evtName: humanizedEventName, vnode: vnode });
};

/**
 * mergeOptions with type verification and fallback to current options
 */
const mergeOptions = (oldOpts: any, newOpts: any) => {
	if (typeof newOpts === 'undefined' || typeof newOpts === 'object') {
		return { ...oldOpts, ...newOpts };
	} else {
		console.warn("v-sticky binding must be an object, 'fallbacking' to previous option set");
		return oldOpts;
	}
};

/**
 * handleEventsReference
 *
 * @desc a reference where handleEvents will be assigned to allow add and remove eventListeners
 */
let handleEventsReference: (e: Event) => any;

export const sticky: Directive<ElementWithSickySidebar> = {
	mounted(el, binding, vnode) {
		const opts = mergeOptions(DEFAULTS, binding.value);

		el[NS] = new StickySidebar(el, opts);

		handleEventsReference = e => {
			handleEvents(e, vnode);
		};

		Object.keys(EVENT_NAMES_MAP).map(evtName => {
			el.addEventListener(evtName, handleEventsReference);
		});
	},

	unmounted(el) {
		const instance = el[NS];

		if (instance) {
			instance.destroy();
			el[NS] = undefined;

			Object.keys(EVENT_NAMES_MAP).map(evtName => {
				el.removeEventListener(evtName, handleEventsReference);
			});
		}
	},

	updated(el, binding) {
		const instance = el[NS];

		if (!instance || isEqual(binding.value, binding.oldValue)) return;

		instance.options = mergeOptions(instance.options, binding.value);
		instance.updateSticky();
	},
};
