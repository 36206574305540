import { Component } from 'vue';

import { VueTransitionVBind } from '@/tools/types';

import LayoutLanding from '../LayoutLanding.vue';
import { landingTransition } from './landingTransition';

export const LAYOUT_TRANSITIONS_MAP = new Map<Component, VueTransitionVBind>();

LAYOUT_TRANSITIONS_MAP.set(LayoutLanding, landingTransition);
