import { isNumber } from 'lodash';

import { ValidationRule } from '@/mixins/validation/validator';

export const numberInRange = ({
	min,
	max,
}: {
	min?: number;
	max?: number;
}): ValidationRule => value => {
	if (!isNumber(value)) {
		return true;
	}

	if (min !== undefined && value < min) {
		return `The number is too small. Minimal value is ${min}`;
	} else if (max !== undefined && value > max) {
		return `The number is too big. Maximal value is ${max}`;
	}

	return true;
};
