import { defineModule, getModule } from '@owlsdepartment/vuex-typed';

import actions from './actions';
import mutations from './mutations';
import state from './state';

const module = defineModule({
	namespaced: true,

	state,
	mutations,
	actions,
});

export const { state: globalState, mutations: globalMutations, actions: globalActions } = getModule(
	module,
	'global',
);
export default module;
