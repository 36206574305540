import { getMutations } from '@owlsdepartment/vuex-typed';

import { UserModel } from '@/store/models/User';
import { ListFetched, ListOf } from '@/store/types';

import { UserState } from './state';

const mutations = {
	userFetched(state: UserState, user: UserModel) {
		state.users[user.id] = user;
	},

	usersFetched(state: UserState, { users }: { users: ListOf<UserModel> }) {
		for (const [key, user] of Object.entries(users)) {
			state.users[key] = user;
		}
	},

	usersListFetched(state: UserState, { name, data }: ListFetched) {
		state.lists[name] = data as any;
	},

	usersListReset(state: UserState, name: string) {
		state.lists[name] = [] as any;
	},
};

export type UserMutations = typeof mutations;
export const userMutations = getMutations(mutations, 'user');

export default mutations;
