import { Dictionary } from 'lodash';

import { ERRORS } from '@/constants/errors/constants';
import { ApiErrors } from '@/store/types';

export type InstanceApiErrors = Dictionary<[string]>;

export function mapErrorMessagesToInstance(errors: ApiErrors, target: InstanceApiErrors) {
	for (const key in errors) {
		if (Array.isArray(errors[key]) && errors[key][0]) {
			const [value] = errors[key] as [string];
			const message = value in ERRORS ? ERRORS[value as keyof typeof ERRORS] : value;

			target[key] = [message];
		}
	}
}
