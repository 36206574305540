import { defineGetters } from '@owlsdepartment/vuex-typed';

import { UserState } from '../user/state';
import state from './state';

const getters = defineGetters(state, {
	isAuthorized: state => !!state.accessToken,

	isTokenValid: state => (): boolean => !!state.expiresAt && state.expiresAt >= new Date(),

	currentUserId: state => state.currentUserId,

	currentUser: (state, getters: any, rootState: any) => {
		if (state.currentUserId) {
			return (rootState.user as UserState).users[state.currentUserId];
		}

		return null;
	},
});

export default getters;
