import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import { Subject } from '@/common/abilities';
import LayoutAdmin from '@/layouts/LayoutAdmin.vue';

import addresses from './addresses';
import orders from './orders';
import organisations from './organisations';
import users from './users';

export default createRouteNode({
	shared: {
		basePath: '/admin',
		meta: {
			abilitySubject: Subject.ADMIN_PANEL,
			layout: {
				component: LayoutAdmin,
			},
		},
	},

	routes: [
		{
			name: 'admin',
			path: '/',
			redirect: { name: 'admin-orders' },
		},
	],

	children: [addresses, orders, organisations, users],
});
