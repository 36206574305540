import { getMutations } from '@owlsdepartment/vuex-typed';

import { ElbowModel } from '@/store/models/Elbow';
import { ListFetched, ListOf } from '@/store/types';

import { ElbowState } from './state';

const mutations = {
	elbowsFetched(state: ElbowState, elbows?: ListOf<ElbowModel>) {
		if (!elbows) return;

		Object.values(elbows).forEach(item => (state.elbows[item.id] = item));
	},

	elbowFetched(state: ElbowState, { elbow }: { elbow: ElbowModel }) {
		state.elbows[elbow.id] = elbow;
	},

	elbowListFetched(state: ElbowState, { name, data }: ListFetched) {
		state.lists[name] = data;
	},

	elbowListReset(state: ElbowState, { name }: { name: string }) {
		state.lists[name] = [];
	},
};

export type ElbowMutations = typeof mutations;
export const { elbowsFetched, elbowFetched, elbowListFetched, elbowListReset } = getMutations(
	mutations,
	'elbow',
);

export default mutations;
