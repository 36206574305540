import { Component } from 'vue';

import { ManualPopup, ManualPopupConfig } from '@/common/popup';

import { ToastSystem } from './ToastSystem';

export class ManualToast extends ManualPopup {
	constructor(
		component: Component,
		{ props = {}, isOpen = false, addTeleport = false }: ManualPopupConfig = {},
	) {
		super(component, { props, isOpen, addTeleport });

		const toastSystem = ToastSystem.instance;

		toastSystem.registerManual(this);
	}

	unregister() {
		ToastSystem.instance.unregisterManual(this);
	}
}
