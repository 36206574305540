import { getState } from '@owlsdepartment/vuex-typed';

import { ElbowModel } from '@/store/models/Elbow';
import { ListOf } from '@/store/types';

const state = () => ({
	elbows: {} as ListOf<ElbowModel>,
	lists: {} as ListOf<Array<number>>,
});

export type ElbowState = ReturnType<typeof state>;
export const { elbows, lists } = getState(state, 'elbow');

export default state;
