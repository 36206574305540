import { isEmpty, isNil } from 'lodash';
import { computed } from 'vue';
import {
	LocationQueryRaw,
	RouteLocationNormalized,
	RouteLocationRaw,
	RouteParams,
	RouteRecordName,
	useRoute,
	useRouter,
} from 'vue-router';

import { syncNavigate } from '@/router/helpers';
import { globalState } from '@/store/modules/global';

interface Essentials {
	n?: RouteRecordName;
	p?: RouteParams;
	q?: LocationQueryRaw;
}

const BACK_ROUTE_KEY = 'back_route';

function extractRouteToSave(
	current: RouteLocationNormalized,
	{ name, params, query }: RouteLocationNormalized,
): string {
	const essentials: Essentials = {};

	if (query[BACK_ROUTE_KEY]) {
		const previousBackRoute = extractBackRoute({ query });

		if (previousBackRoute && previousBackRoute.name === current.name) {
			return '';
		}
	}

	if (!isNil(name)) {
		essentials.n = name;
	}
	if (!isEmpty(params)) {
		essentials.p = params;
	}
	if (!isEmpty(query)) {
		essentials.q = query;
	}

	return isEmpty(essentials) ? '' : JSON.stringify(essentials);
}

function extractBackRoute({ query }: { query: RouteLocationNormalized['query'] }) {
	const savedRoute = query[BACK_ROUTE_KEY] as string | undefined | null;

	if (!savedRoute) {
		return null;
	}

	const { n, p, q }: Essentials = JSON.parse(savedRoute);
	const backRoute: RouteLocationRaw = { name: n ?? undefined, params: p, query: q };

	return backRoute;
}

export function useBackRoute(fallback: RouteLocationRaw = {}) {
	const $route = useRoute();
	const $router = useRouter();

	const from = globalState.previousRoute();
	const routeToSave = from ? extractRouteToSave($route, from) : '';

	if (routeToSave) {
		syncNavigate(() => {
			return $router.replace({
				query: {
					...$route.query,
					[BACK_ROUTE_KEY]: routeToSave,
				},
			});
		});
	}

	const backRoute = computed(() => extractBackRoute($route) ?? fallback);

	return {
		backRoute,

		goBack: () => {
			return $router.push(backRoute.value);
		},
	};
}
