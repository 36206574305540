import { AliasesMap } from '@casl/ability/dist/types/types';
import { without } from 'lodash';

/**
 * Holds all actions a user can do
 */
export enum Action {
	access = 'access',
	all = 'all',
}

export const ACTION_ALIASES: AliasesMap = {
	all: without(Object.values(Action), Action.all),
};

// eslint-disable-next-line prettier/prettier
export type ActionType = `${Action}`;
