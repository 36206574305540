export enum ProsthesisType {
	// Below
	GlazeOneBelow = 'glaze-one-below',
	GlazeKidsBelow = 'glaze-kids-below',
	GlazeCosmetics = 'glaze-cosmetics-below',
	WhizzlinkBelow = 'whizzlink-below',
	WhizzlinkKidsBelow = 'whizzlink-kids-below',
	WhizzlinkEcoBelow = 'whizzlink-eco-below',
	// Above
	GlazeOneAbove = 'glaze-one-above',
	WhizzlinkAbove = 'whizzlink-above',
	WhizzlinkEcoAbove = 'whizzlink-eco-above',

	// TERMINAL DEVICE ONLY
	NullProsthesis = 'null-prosthesis',
}

export const ProsthesisDevicesDescription: Record<ProsthesisType, string> = {
	[ProsthesisType.GlazeOneBelow]:
		'You can select multiple devices. Our interchangeable system, used in Glaze products, allows for quick and convenient change of terminal devices.',
	[ProsthesisType.GlazeKidsBelow]:
		'You can select multiple devices. Our interchangeable system, used in Glaze products, allows for quick and convenient change of terminal devices.',
	[ProsthesisType.GlazeCosmetics]:
		'We have prepared various options of detailed and realistic cosmetic gloves that will allow you to cover your device. Skin gloves can be used interchangeably with terminal devices.',
	[ProsthesisType.WhizzlinkBelow]:
		"Whizzlink device is designed to work with the most myoelectric hands available on the market. Unfortunately, different myoelectric systems are not interchangeable. Browse the producers' catalogs and choose one of the hands. Your Whizzlink will be configured and optimized for the device of your choosing.",
	[ProsthesisType.WhizzlinkKidsBelow]:
		"Whizzlink Kids is a myoelectric solution designed for thriving, energetic children. Browse the producers' catalogs and choose one of the hands. Your Whizzlink Kids will be configured and optimized for the device of your choice.",
	[ProsthesisType.WhizzlinkEcoBelow]:
		"Whizzlink Eco is probably the best solution on the market that combines modern aesthetics with functionality provided by myoelectric hand. Browse the producers' catalogs and choose one of the hands. Your Whizzlink Eco will be configured and optimized for the device of your choice.",
	[ProsthesisType.GlazeOneAbove]:
		'You can select multiple devices. Our interchangeable system, used in Glaze products, allows for quick and convenient change of terminal devices.',
	[ProsthesisType.WhizzlinkAbove]:
		"Whizzlink device is designed to work with the most myoelectric hands available on the market. Unfortunately, different myoelectric systems are not interchangeable. Browse the producers' catalogs and choose one of the hands. Your Whizzlink will be configured and optimized for the device of your choosing.",
	[ProsthesisType.WhizzlinkEcoAbove]:
		"Whizzlink Eco is probably the best solution on the market that combines modern aesthetics with functionality provided by myoelectric hand. Browse the producers' catalogs and choose one of the hands. Your Whizzlink Eco will be configured and optimized for the device of your choice.",
	[ProsthesisType.NullProsthesis]: '',
};
