import { Popup, PopupSystem } from '@/common/popup';

import { ManualToast } from './ManualToast';

let instance: ToastSystem;

export class ToastSystem extends PopupSystem<Popup, ManualToast> {
	// eslint-disable-next-line prettier/prettier
	static override systemId = 'toast-system';

	// eslint-disable-next-line prettier/prettier
	static override get instance(): ToastSystem {
		if (!instance) {
			instance = new ToastSystem();
		}

		return instance;
	}
}
