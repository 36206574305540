import { ActionContext } from '@owlsdepartment/vuex-typed';
import { Moment } from 'moment-timezone';
import { normalize } from 'normalizr';
import { v4 as uuid } from 'uuid';

import { moduleDecorators } from '@/common/loading';
import { OrderModel, SimpleOrderModel } from '@/store/models/Order';
import { ApiCollection, CreateResponse, ListOf, UpdateResponse } from '@/store/types';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';
import { applyQuery } from '@/tools/helpers';

import { OrderGetters } from './getters';
import { OrderMutations } from './mutations';
import { pagedOrderListSchema } from './schemas';
import { OrderState } from './state';
import {
	ChangeOrderStatusPayload,
	CreateOrderPayload,
	FetchOrdersListPayload,
	FetchUserOrderListPayload,
	OrdersStatusCount,
	SearchOrdersPayload,
	UpdateColorPatternsPayload,
	UpdateElbowPayload,
	UpdateOrderDetailsPayload,
	UpdateOrderPayload,
	UpdatePhotosPayload,
	UpdateScansPayload,
	UpdateSkinGlovePayload,
	UpdateSocketPayload,
	UpdateTerminalPayload,
} from './types';

type Ctx = ActionContext<OrderState, OrderGetters, OrderMutations>;

const { loading } = moduleDecorators('order');
const defaultPerPage = 20;

async function fetchOrderByGuid(guid: string, commit: Ctx['commit']) {
	const order = await api.get<OrderModel>(`/order/${guid}`);

	commit('orderFetched', { order });

	return order;
}

class OrderActions {
	@loading()
	async createOrderDraft(
		ctx: Ctx,
		{ prosthesis_id, step, terminal_device_only = false }: CreateOrderPayload,
	) {
		const guid = uuid();

		await api.post<CreateResponse>('/order', { guid, step, prosthesis_id, terminal_device_only });

		return { guid };
	}

	@loading()
	async updateOrderDraft({ commit }: Ctx, { id, guid, ...payload }: UpdateOrderPayload) {
		const response = await api.put<UpdateResponse>(`/order/${id}`, payload);

		if (payload.address_id) {
			await fetchOrderByGuid(guid, commit);
		} else {
			commit('updateOrder', { guid, ...payload });
		}

		return response;
	}

	@loading()
	async fetchOrderByGuid({ commit }: Ctx, guid: string) {
		return fetchOrderByGuid(guid, commit);
	}

	@loading({ presets: { id: 'status' } })
	async fetchUserOrderList(
		{ commit, rootGetters }: Ctx,
		{ page, perPage = defaultPerPage, status }: FetchUserOrderListPayload,
	) {
		const userId: number = rootGetters['auth/currentUserId'];

		if (!page) {
			commit('orderListReset', { name: status });
			page = 1;
		}

		const response = await api.get(
			`/user/${userId}/order/status/${status}?page=${page}&per_page=${perPage}`,
		);
		const { entities, result } = normalize<any, { orders: ListOf<OrderModel> }>(
			response,
			pagedOrderListSchema,
		);

		commit('ordersFetched', entities);
		commit('orderListFetched', { name: status, data: result });

		return response;
	}

	@loading({ presets: { id: 'status' } })
	async fetchOrdersList(
		{ commit }: Ctx,
		{ page, perPage = defaultPerPage, status }: FetchOrdersListPayload,
	) {
		const name = `all_${status}`;

		if (!page) {
			commit('orderListReset', { name });
			page = 1;
		}

		const url = applyQuery(`/order/status/${status}`, { page, per_page: perPage });
		const response = await api.get<ApiCollection<SimpleOrderModel>>(url);
		const { entities, result } = normalize<any, { orders: ListOf<SimpleOrderModel> }>(
			response,
			pagedOrderListSchema,
		);

		commit('simpleOrdersFetched', entities);
		commit('simpleOrderListFetched', { name, data: result });

		return response;
	}

	@loading()
	async fetchOrdersStatusCount({ commit }: Ctx) {
		const response = await api.get<OrdersStatusCount>('order/status/counted');

		commit('ordersStatusCountFetched', response);

		return response;
	}

	@loading({ presets: { id: true } })
	async deleteOrderById({ commit }: Ctx, id: number): Promise<void> {
		await api.delete(`/order/${id}`);
		commit('orderDeleted', id);
	}

	@loading({ presets: { id: true } })
	async updateDeliveryDate(ctx: Ctx, { id, date }: { id: number; date: Moment }) {
		return await api.put<string>(`/order/delivery-date/${id}`, {
			delivery_date: date.format('YYYY-MM-DD'),
		});
	}

	@loading({ presets: { id: true } })
	async changeOrderStatus(ctx: Ctx, { id, status }: ChangeOrderStatusPayload) {
		return await api.put<string>(`/order/status/${id}`, { status });
	}

	@loading()
	async updateTerminal({ commit }: Ctx, { guid, ...data }: UpdateTerminalPayload) {
		const res = await api.post<UpdateResponse>(`/terminal-orders/${guid}`, data);

		await fetchOrderByGuid(guid, commit);

		return res;
	}

	@loading()
	async updateSocket({ commit }: Ctx, { guid, ...data }: UpdateSocketPayload) {
		const res = await api.post<UpdateResponse>(`/bionic-hands-orders/${guid}`, data);

		await fetchOrderByGuid(guid, commit);

		return res;
	}

	@loading()
	async updateColorPatterns({ commit }: Ctx, { guid, ...data }: UpdateColorPatternsPayload) {
		const res = await api.post<UpdateResponse>(`/color-pattern-orders/${guid}`, data);

		await fetchOrderByGuid(guid, commit);

		return res;
	}

	@loading()
	async updateSkinGlove({ commit }: Ctx, { guid, ...data }: UpdateSkinGlovePayload) {
		const res = await api.post<UpdateResponse>(`/skin-glove-orders/${guid}`, data);

		await fetchOrderByGuid(guid, commit);

		return res;
	}

	@loading()
	async updateElbow({ commit }: Ctx, { guid, ...data }: UpdateElbowPayload) {
		const res = await api.post<UpdateResponse>(`/elbow-orders/${guid}`, data);

		await fetchOrderByGuid(guid, commit);

		return res;
	}

	@loading()
	async updateOrderDetails({ commit }: Ctx, { guid, ...data }: UpdateOrderDetailsPayload) {
		const res = await api.post<UpdateResponse>(`/order-details/${guid}`, data);

		await fetchOrderByGuid(guid, commit);

		return res;
	}

	@loading()
	async updateScans({ commit }: Ctx, { guid, ...data }: UpdateScansPayload) {
		const res = await api.post<UpdateResponse>(`/scans/${guid}`, data);

		await fetchOrderByGuid(guid, commit);

		return res;
	}

	@loading()
	async updatePhotos({ commit }: Ctx, { guid, ...data }: UpdatePhotosPayload) {
		const res = await api.post<UpdateResponse>(`photos/${guid}`, data);

		await fetchOrderByGuid(guid, commit);

		return res;
	}

	@loading({ presets: { id: true } })
	async deletePhoto(
		{ commit }: Ctx,
		{ id, orderGuid }: { id: number; orderGuid: string },
	): Promise<void> {
		await api.delete(`/photos/${id}`);
		commit('photoDeleted', { id, orderGuid });
	}

	@loading()
	async finaliseOrder(ctx: Ctx, id: number) {
		return api.put<UpdateResponse>(`/order/${id}/place`);
	}

	@loading()
	async searchOrders(ctx: Ctx, { query, status }: SearchOrdersPayload) {
		const url = applyQuery(`/order/status/${status}`, { search: query });

		return await api.get(url);
	}
}

export default createStoreClassInstance(OrderActions);
