import { getState } from '@owlsdepartment/vuex-typed';

import { ListOf } from '@/store/types';

const state = () => ({
	urls: {} as ListOf<string>,
});

export type Tm3dState = ReturnType<typeof state>;
export const { urls } = getState(state, 'tm3d');

export default state;
