import { OrderModel, SimpleOrderModel } from '@/store/models/Order';
import { ListOf, NormalizedCollection } from '@/store/types';

import { OrdersStatusCount } from './types';

const state = () => {
	const count: Record<keyof OrdersStatusCount, number> = {
		draft: 0,
		accepted: 0,
		archived: 0,
		placed: 0,
		rejected: 0,
	};

	return {
		orders: {} as ListOf<OrderModel>,
		simpleOrders: {} as ListOf<SimpleOrderModel>,
		lists: {} as ListOf<NormalizedCollection<string>>,
		simpleLists: {} as ListOf<NormalizedCollection<string>>,
		count,
	};
};

export type OrderState = ReturnType<typeof state>;
export default state;
