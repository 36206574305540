import { computed, onMounted, onUpdated, Ref, ref, Slots } from 'vue';

export function useHasSlot(
	slotName: string,
	slots: Slots,
	optionalCondition?: (hasSlot: boolean) => boolean,
): Ref<boolean> {
	const hasSlot = ref(false);
	const updateHasSlot = () => {
		hasSlot.value = !!slots[slotName];
	};

	onMounted(updateHasSlot);
	onUpdated(updateHasSlot);

	return computed(() => {
		if (!optionalCondition) return hasSlot.value;

		return optionalCondition(hasSlot.value);
	});
}

export function useHasDynamicSlot(
	slotNames: string[],
	slots: Slots,
): (slotName: string) => boolean {
	const dynamicSlots = slotNames.reduce((acc, key) => {
		acc[key] = useHasSlot(key, slots);

		return acc;
	}, {} as Record<string, Ref<boolean>>);

	return slotName => {
		return dynamicSlots[slotName]?.value ?? false;
	};
}
