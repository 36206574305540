import { getState } from '@owlsdepartment/vuex-typed';

import { OrganisationModel, SimpleOrganisationModel } from '@/store/models/Organisation';
import { ListOf, NormalizedCollection } from '@/store/types';

const state = () => ({
	organisations: {} as ListOf<OrganisationModel>,
	simpleOrganisations: {} as ListOf<SimpleOrganisationModel>,
	lists: {} as ListOf<NormalizedCollection<string>>,
	allSimpleOrganizations: [] as number[],
});

export type OrganisationState = ReturnType<typeof state>;
export const organisationState = getState(state, 'organisation');

export default state;
