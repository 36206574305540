import { ref, watch } from 'vue';

const documentState = ref<DocumentReadyState>('loading');

document.addEventListener('readystatechange', () => {
	documentState.value = document.readyState;
});

export function onDocumentInteractive(cb: () => void) {
	if (documentState.value !== 'loading') {
		return cb();
	}

	const turnOffWatch = watch(
		() => documentState.value,
		() => {
			if (documentState.value !== 'loading') {
				cb();
				turnOffWatch();
			}
		},
	);
}

export function onDocumentComplete(cb: () => void) {
	if (documentState.value === 'complete') {
		return cb();
	}

	const turnOffWatch = watch(
		() => documentState.value,
		() => {
			if (documentState.value === 'complete') {
				cb();
				turnOffWatch();
			}
		},
	);
}
