import { getGetters } from '@owlsdepartment/vuex-typed';

import { UserModel } from '@/store/models/User';
import { ApiCollection } from '@/store/types';

import { UserState } from './state';

const getters = {
	userById: (state: UserState) => (id: number): UserModel | undefined => state.users[id],

	listByName: (state: UserState) => (name: string): ApiCollection<UserModel> => {
		const list = state.lists[name];

		return {
			data: list?.data?.map(id => state.users[id]) ?? [],
			meta: list?.meta,
		};
	},

	listDefault: () => userGetters.listByName()('default'),

	listOfOrganisationId: () => (organisation_id: number): ApiCollection<UserModel> => {
		return userGetters.listByName()(`of_organisation_${organisation_id}`);
	},
};

export type UserGetters = typeof getters;
export const userGetters = getGetters(getters, 'user');

export default getters;
