import { defineGetters } from '@owlsdepartment/vuex-typed';

import { OrderModel, SimpleOrderModel } from '@/store/models/Order';
import { ApiCollection, Meta } from '@/store/types';

import state, { OrderState } from './state';
import { OrderStatus } from './types';

const getters = defineGetters(state, {
	getOrderByGuid: (state: OrderState) => (guid: string): OrderModel | undefined => {
		return state.orders[guid];
	},

	getOrderById: (state: OrderState) => (id: number): OrderModel | undefined => {
		return Object.values(state.orders).find(order => order.id == id);
	},

	orderListByName: (state: OrderState) => (name: string): ApiCollection<OrderModel> => {
		const list = state.lists[name];

		return {
			data: list?.data?.map(id => state.orders[id]) ?? [],
			meta: list?.meta ? (list.meta as Meta) : undefined,
		};
	},

	getSimpleOrderById: (state: OrderState) => (id: number): SimpleOrderModel | undefined => {
		return Object.values(state.simpleOrders).find(order => order.id == id);
	},

	simpleOrderListByName: (state: OrderState) => (name: string): ApiCollection<SimpleOrderModel> => {
		const list = state.simpleLists[name];

		return {
			data: list?.data?.map(id => state.simpleOrders[id]) ?? [],
			meta: list?.meta ? (list.meta as Meta) : undefined,
		};
	},

	orderDraftList: (state, getters): ApiCollection<OrderModel> => getters.orderListByName('drafted'),

	orderReadyList: (state, getters): ApiCollection<OrderModel> => getters.orderListByName('ready'),

	orderPlacedList: (state, getters): ApiCollection<OrderModel> => getters.orderListByName('placed'),

	allOrdersList: (state, getters) => (status: OrderStatus): ApiCollection<SimpleOrderModel> =>
		getters.simpleOrderListByName(`all_${status}`),
});

export type OrderGetters = typeof getters;
export default getters;
