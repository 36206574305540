import { isString } from 'lodash';
import { NavigationGuard, RouteLocationRaw } from 'vue-router';

import { orderPath, OrderStep } from '@/constants/order/constants';
import { globalActions } from '@/store/modules/global';
import { orderActions, orderGetters } from '@/store/modules/order';

async function getOrder(id: string) {
	if (!orderGetters.getOrderByGuid()(id)) {
		await globalActions.addGlobalLoader(() => orderActions.fetchOrderByGuid(id));
	}

	return orderGetters.getOrderByGuid()(id);
}

export const orderCustomizationDynamicRedirect: NavigationGuard = async (to, from, next) => {
	const { orderGuid } = to.params;
	const order = await getOrder(isString(orderGuid) ? orderGuid : orderGuid[0]);

	if (!order) {
		throw new Error(`Order not found for "${orderGuid}"`);
	}

	const overrideTo: RouteLocationRaw = {
		params: { orderGuid },
	};
	const path = orderPath[order.prosthesis.slug];

	overrideTo.name = path?.[0] ?? OrderStep.ClientDetails;

	next(overrideTo);
};
