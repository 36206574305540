import { NavigationGuard } from 'vue-router';

import { orderPath, OrderStep } from '@/constants/order/constants';
import { orderActions, orderGetters } from '@/store/modules/order';

export const checkCustomizationStep: NavigationGuard = async (to, from, next) => {
	let { orderGuid } = to.params;

	if (!orderGuid) {
		return next();
	}

	if (Array.isArray(orderGuid)) {
		orderGuid = orderGuid[0];
	}

	if (orderGuid && !orderGetters.getOrderByGuid()(orderGuid)) {
		await orderActions.fetchOrderByGuid(orderGuid);
	}

	const order = orderGetters.getOrderByGuid()(orderGuid);
	const orderSteps = Object.values(OrderStep);

	if (!order || !orderSteps.includes(to?.name as any)) {
		return next();
	}

	if (order.terminal_device_only) {
		return next();
	}

	const path = orderPath[order.prosthesis.slug];

	if (!path.includes(to.name as OrderStep)) {
		return next('404');
	}

	return next();
};
