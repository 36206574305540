import { RouteLocationRaw } from 'vue-router';

import { OrderStep } from '@/constants/order/constants';
import { OrderSectionNavTabModel } from '@/store/models/OrderNavTabsItem';
import { orderGetters } from '@/store/modules/order';
import { OrderStatusRejected } from '@/store/modules/order/types';

import { OrderSection, orderSections } from '../sections/constants';

export enum OrderSectionsFlow {
	Customization = 'customization',
	Prosthetist = 'prosthetist',
	Edit = 'edit',
}

const toFactory = (orderGuid: string) => (name: string): RouteLocationRaw => ({
	name,
	params: { orderGuid },
});
const navItemFactory = (orderGuid: string) => (
	section: OrderSection,
	label: string,
): OrderSectionNavTabModel => {
	const step = orderSections[section][0];

	return {
		label,
		to: toFactory(orderGuid)(step),
		matched_routes: [step],
		steps: [step],
	};
};

function customizationSections(orderGuid: string): Array<OrderSectionNavTabModel> {
	const getTo = toFactory(orderGuid);
	const getNavItem = navItemFactory(orderGuid);

	return [
		{
			to: { name: OrderStep.New },
			matched_routes: [OrderStep.New],
			label: 'Type of Prosthesis',
			steps: [],
		},
		{
			to: getTo('order-customization'),
			matched_routes: ['order-customization', ...orderSections[OrderSection.Customize]],
			label: 'Customizer',
			steps: [...orderSections[OrderSection.Customize]],
		},
		getNavItem(OrderSection.ClientsDetails, 'Clients Details'),
		getNavItem(OrderSection.OrderPreview, 'Order Preview'),
	];
}

function prosthetistSections(orderGuid: string): Array<OrderSectionNavTabModel> {
	const getNavItem = navItemFactory(orderGuid);

	const order = orderGetters.getOrderByGuid()(orderGuid);

	if (order?.terminal_device_only) {
		return [
			getNavItem(OrderSection.ShippingAddress, 'Shipping'),
			getNavItem(OrderSection.OrderFinalise, 'Order Preview'),
		];
	}

	if (order?.prosthesis.slug.includes('above')) {
		return [
			getNavItem(OrderSection.ShippingAddress, 'Shipping'),
			getNavItem(OrderSection.OrderFinalise, 'Order Preview'),
		];
	}

	return [
		getNavItem(OrderSection.AddingScan, 'Adding Scan'),
		getNavItem(OrderSection.Alignment, 'Alignment'),
		getNavItem(OrderSection.Files, 'Files'),
		getNavItem(OrderSection.ShippingAddress, 'Shipping'),
		getNavItem(OrderSection.OrderFinalise, 'Order Preview'),
	];
}

enum RejectedStatuses {
	IncorrectMeasurements = 'rejected_incorrect_measurements',
	IncorrectAttachments = 'rejected_incorrect_attachments',
	IncorrectScan = 'rejected_incorrect_scan',
}

function editSections(orderGuid: string): Array<OrderSectionNavTabModel> {
	const getNavItem = navItemFactory(orderGuid);
	const order = orderGetters.getOrderByGuid()(orderGuid);

	if (!order) return [];

	const orderStatus = order.order_status as OrderStatusRejected;

	if (orderStatus === RejectedStatuses.IncorrectMeasurements) {
		return [getNavItem(OrderSection.ClientsDetails, 'Clients Details')];
	}

	if (orderStatus === RejectedStatuses.IncorrectAttachments) {
		return [getNavItem(OrderSection.Files, 'Files')];
	}

	if (orderStatus === RejectedStatuses.IncorrectScan) {
		return [getNavItem(OrderSection.AddingScan, 'Adding Scan')];
	}

	return [];
}

export function getOrderSectionsNav(
	orderGuid: string,
	flow: OrderSectionsFlow,
): Array<OrderSectionNavTabModel> {
	switch (flow) {
		case OrderSectionsFlow.Customization:
			return customizationSections(orderGuid);
		case OrderSectionsFlow.Prosthetist:
			return prosthetistSections(orderGuid);
		case OrderSectionsFlow.Edit:
			return editSections(orderGuid);
	}
}
