import { defineComponent, h } from 'vue';

import OrderRejectedDialog from '@/components/order/rejected/OrderRejectedDialog.vue';
import { NotificationModel } from '@/store/models/Notification';
import { authGetters } from '@/store/modules/auth';
import { notificationActions } from '@/store/modules/notification/actions';
import { notificationGetters } from '@/store/modules/notification/getters';

const REFRESH_NOTIFICATIONS_INTERVAL = 30000;

let isNotificationPopupOpen = false;

export default defineComponent({
	computed: {
		...authGetters.map(['currentUserId']),

		notifications(): NotificationModel[] {
			if (!this.currentUserId) {
				return [];
			}

			return notificationGetters.notificationListByUserIdAndStatus()(this.currentUserId, 'unread');
		},

		recentNotification(): NotificationModel | null {
			const notifications = this.notifications.filter(n => n.type === 'order_rejected');

			return notifications.length ? notifications[0] : null;
		},
	},

	watch: {
		currentUserId: {
			handler() {
				this.refreshNotifications();
			},
			immediate: true,
		},

		recentNotification: {
			handler(
				notification: NotificationModel | null,
				previous: NotificationModel | null | undefined,
			) {
				if (!notification || notification === previous) {
					return;
				}

				this.openNotificationPopup(notification);
			},
			immediate: true,
		},
	},

	mounted() {
		// eventBus.$on('refreshNotifications', this.refreshNotifications);
		setInterval(this.refreshNotifications, REFRESH_NOTIFICATIONS_INTERVAL);
	},

	methods: {
		refreshNotifications() {
			if (!this.currentUserId || isNotificationPopupOpen) {
				return;
			}

			notificationActions.fetchNotificationList({
				user_id: this.currentUserId,
				status: 'unread',
			});
		},

		openNotificationPopup(notification: NotificationModel) {
			if (isNotificationPopupOpen) {
				return;
			}

			isNotificationPopupOpen = true;

			this.$dialog.open({
				requireAction: true,
				contentSlot: ({ close: closeFunc }) => {
					const close = () => {
						isNotificationPopupOpen = false;
						this.refreshNotifications();
						closeFunc();
					};

					return h(OrderRejectedDialog as any, { close, notification });
				},
			});
		},
	},
});
