import { Ability, AbilityClass, createAliasResolver } from '@casl/ability';
import { AliasesMap } from '@casl/ability/dist/types/types';

export function createAppAbility<AppAbility extends Ability = Ability>(aliasMap: AliasesMap = {}) {
	const AppAbilityClass = Ability as AbilityClass<AppAbility>;

	const resolveAction = createAliasResolver(aliasMap);

	return new AppAbilityClass([], { resolveAction });
}
