/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dictionary, isFunction, isString, last } from 'lodash';

import { loadingMutations } from './loadingModule';

const setLoading = (key: string, value: boolean) =>
	loadingMutations.changeLoadingState([key, value]);

/**
 * Helper for loading decorator, applying predefined module name.
 */
export const moduleDecorators = (moduleName: string) => ({
	loading: (options?: LoadingOptions) => loading(options, moduleName),
});

/**
 * Decorator for Vuex actions, to mark when async function is working.
 */
export function loading(options: LoadingOptions = {}, moduleName = '') {
	return (_target: any, key: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
		moduleName = getModuleName(moduleName);

		const { presets = {}, custom = [] } = options;
		const basicKey = moduleName ? `${moduleName}/${key}` : key;
		const originalMethod: AnyFunction = descriptor.value;

		const action = async (...args: any[]) => {
			const partialArgs = args.slice(1, args.length);
			const loadingData: MarkLoadingData = {
				basicKey,
				moduleName,
				key,
				args: partialArgs,
			};

			setLoading(basicKey, true);
			useCustomKeys(custom, loadingData, true);
			usePresets(presets, loadingData, true);

			try {
				return await originalMethod(...args);
			} finally {
				setLoading(basicKey, false);
				useCustomKeys(custom, loadingData, false);
				usePresets(presets, loadingData, false);
			}
		};

		return {
			...descriptor,
			value: action,
		};
	};
}

function getModuleName(module: string): string {
	return last(module) === '/' ? module.slice(0, module.length - 1) : module;
}

function useCustomKeys(customKeys: CustomKeys, { args }: MarkLoadingData, value: boolean) {
	let key: LoadingKey;

	for (const action of customKeys) {
		if (isFunction(action)) {
			key = action(...args);
		} else {
			key = action;
		}

		handleLoadingKey(key, value);
	}
}

const registeredPresets: Dictionary<PresetFunction> = {};

function usePresets(presets: Presets, data: MarkLoadingData, value: boolean) {
	let preset: PresetFunction;
	let key: LoadingKey | null;

	for (const presetName in presets) {
		if (presetName in registeredPresets) {
			preset = registeredPresets[presetName];
			key = preset(presets[presetName], data);

			if (key) {
				handleLoadingKey(key, value);
			}
		}
	}
}

function handleLoadingKey(key: LoadingKey, value: boolean) {
	if (isString(key)) {
		setLoading(key, value);
	} else {
		key.forEach(k => setLoading(k, value));
	}
}

export function registerPreset(name: string, func: PresetFunction): void {
	if (name in registeredPresets) {
		throw new Error('[MarkLoading] Preset with given name already exists!');
	}

	registeredPresets[name] = func;
}

export function registerPresets(presets: PresetsToRegister): void {
	presets.forEach(({ name, preset }) => {
		try {
			registerPreset(name, preset);
		} catch (error) {
			console.error(error);
		}
	});
}

export function unregisterPreset(name: string): void {
	if (name in registeredPresets) {
		delete registeredPresets[name];
	}
}

interface LoadingOptions {
	presets?: Presets;
	custom?: CustomKeys;
}

type LoadingKey = string | string[];

type CustomKeys = (LoadingKey | AnyFunction<LoadingKey>)[];

type AnyFunction<R = any> = (...args: any[]) => R;

export interface Presets {
	[key: string]: any;
}

export type PresetFunction = (presetValue: any, data: MarkLoadingData) => LoadingKey | null;

export interface MarkLoadingData {
	moduleName: string;
	key: string;
	basicKey: string;
	args: any[];
}

export type PresetsToRegister = { name: string; preset: PresetFunction }[];
