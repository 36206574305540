import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { syncNavigate } from '@/router/helpers';

export function useSyncPageQuery(props: { page: number | null }) {
	const $router = useRouter();
	const $route = useRoute();

	watch(
		() => props.page,
		() => {
			if (!props.page || props.page < 1) {
				syncNavigate(() => {
					return $router.replace({
						query: {
							...$route.query,
							page: '1',
						},
					});
				});
			}
		},
		{ immediate: true },
	);

	return {
		onNewPage: (page?: number) => {
			if (page && page >= 1 && props.page !== page) {
				syncNavigate(() => {
					return $router.replace({
						query: {
							...$route.query,
							page: `${page}`,
						},
					});
				});
			}
		},
	};
}
