import axios from 'axios';

import { $isLoading } from '@/common/loading';
import { authActions, authGetters, authState } from '@/store/modules/auth';

let tokenRefreshing = Promise.resolve();
const isRefreshTokenLoading = (): boolean => $isLoading('auth/refreshToken');

export function applyOauthApi() {
	axios.interceptors.request.use(
		async config => {
			const isTokenRequired = !config.suppressToken;
			const isTokenValid: boolean = authGetters.isTokenValid()();

			if (!isTokenRequired) return config;
			if (!isTokenValid) {
				try {
					await tryRefreshToken();
				} catch (error) {
					return config;
				}
			}

			config.headers.Authorization = `${authState.tokenType()} ${authState.accessToken()}`;

			return config;
		},
		error => Promise.reject(error),
	);
	axios.interceptors.response.use(
		response => response,
		error => {
			if (error.response && error.response.status === 401) {
				authActions.passiveLogout();
			}
			return Promise.reject(error);
		},
	);
}

async function tryRefreshToken() {
	if (isRefreshTokenLoading()) {
		return await tokenRefreshing;
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	let resolveRefreshing: () => void = () => {};
	tokenRefreshing = new Promise(resolve => (resolveRefreshing = resolve));

	try {
		await authActions.refreshToken();
		// eslint-disable-next-line prettier/prettier
	} catch (error: any) {
		if (error.response) {
			authActions.passiveLogout();

			throw error;
		}
	} finally {
		resolveRefreshing();
	}
}
