import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import LayoutDefault from '@/layouts/LayoutDefault.vue';

const View = () => import('@/views/ViewTermsAndConditions.vue');

export default createRouteNode({
	shared: {
		basePath: '/terms-and-conditions',
		meta: {
			layout: {
				component: LayoutDefault,
				props: {},
			},
		},
	},

	routes: [
		{
			path: '/',
			name: 'terms-and-conditions',
			component: View,
		},
	],
});
