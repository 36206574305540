import { Component } from 'vue';

import { ManualPopup, ManualPopupConfig } from '@/common/popup';

import { DialogSystem } from './DialogSystem';

export class ManualDialog extends ManualPopup {
	constructor(
		component: Component,
		{ props = {}, isOpen = false, addTeleport = false }: ManualPopupConfig = {},
	) {
		super(component, { props, isOpen, addTeleport });

		const dialogSystem = DialogSystem.instance;

		dialogSystem.registerManual(this);
	}

	unregister() {
		DialogSystem.instance.unregisterManual(this);
	}
}
