import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import { UserType } from '@/constants/users/constants';
import { authActions, authGetters } from '@/store/modules/auth';
import ViewNotFound from '@/views/ViewNotFound.vue';

export default createRouteNode({
	shared: {
		basePath: '/',
	},

	routes: [
		{
			path: '/',
			name: 'home',
			beforeEnter: async (to: any, from: any, next: any) => {
				if (!authGetters.currentUser()) {
					await authActions.fetchCurrentUser();
				}

				const role = authGetters.currentUser()?.role;

				next({ name: role === UserType.administrator ? 'admin' : 'dashboard' });
			},
		} as any,
		{
			path: '/:catchAll(.*)',
			name: '404',
			component: ViewNotFound,
		},
	],
});
