import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import { Subject } from '@/common/abilities';
import LayoutAdmin from '@/layouts/LayoutAdmin.vue';
import { extractPage, passParamsToProps } from '@/router/helpers';

const ViewAdminUsers = () => import('@/views/admin/users/ViewAdminUsers.vue');
const ViewAdminUsersCreate = () => import('@/views/admin/users/ViewAdminUsersCreate.vue');
const ViewAdminUsersEdit = () => import('@/views/admin/users/ViewAdminUsersEdit.vue');

export default createRouteNode({
	shared: {
		basePath: '/users',
		meta: {
			abilitySubject: Subject.ADMIN_PANEL,
			layout: {
				component: LayoutAdmin,
			},
		},
	},

	routes: [
		{
			name: 'admin-users',
			path: '/',
			component: ViewAdminUsers,
			props: ({ query }) => ({
				page: extractPage(query.page),
			}),
		},
		{
			name: 'admin-users-create',
			path: '/create',
			component: ViewAdminUsersCreate,
		},
		{
			name: 'admin-users-edit',
			path: '/edit/:id',
			component: ViewAdminUsersEdit,
			props: passParamsToProps({ id: Number }),
		},
	],
});
