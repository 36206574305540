import { ActionContext, getActions } from '@owlsdepartment/vuex-typed';
import { isUndefined, omitBy } from 'lodash';
import { normalize } from 'normalizr';

import { moduleDecorators } from '@/common/loading';
import { UserModel } from '@/store/models/User';
import {
	ApiCollection,
	CreateResponse,
	DeleteResponse,
	ListOf,
	PaginatedPayload,
	UpdateResponse,
} from '@/store/types';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';
import { applyQuery } from '@/tools/helpers';

import { UserGetters } from './getters';
import { UserMutations } from './mutations';
import { pagedUserListSchema } from './schemas';
import { UserState } from './state';
import { FetchUsersOfOrganisationListPayload } from './types';
import { CreateUserPayload, UpdateUserPayload } from './types';

type Ctx = ActionContext<UserState, UserGetters, UserMutations>;

const { loading } = moduleDecorators('user');
const DEFAULT_PER_PAGE = 20;

class UserActions {
	@loading()
	async fetchUsersOfOrganisationList(
		{ commit }: Ctx,
		{ organisation_id, page, perPage = DEFAULT_PER_PAGE }: FetchUsersOfOrganisationListPayload,
	) {
		const name = `of_organisation_${organisation_id}`;

		if (!page) {
			commit('usersListReset', name);
			page = 1;
		}

		const url = applyQuery(`/organisation/${organisation_id}/users`, { page, per_page: perPage });
		const response = await api.get<ApiCollection<UserModel>>(url);
		const { entities, result } = normalize<any, { users: ListOf<UserModel> }>(
			response,
			pagedUserListSchema,
		);

		if (entities.users) {
			commit('usersFetched', entities);
		}
		commit('usersListFetched', { name, data: result });

		return response;
	}

	@loading()
	async fetchUsersList({ commit }: Ctx, { perPage = DEFAULT_PER_PAGE, page }: PaginatedPayload) {
		const name = 'default';

		if (!page) {
			page = 1;
			commit('usersListReset', name);
		}

		const path = applyQuery('/users', { page, per_page: perPage });
		const response = await api.get<ApiCollection<UserModel>>(path);
		const { entities, result } = normalize<any, { users: ListOf<UserModel> }>(
			response,
			pagedUserListSchema,
		);

		commit('usersFetched', entities);
		commit('usersListFetched', { name, data: result });

		return response;
	}

	@loading({ presets: { id: true } })
	async fetchUser({ commit }: Ctx, id: number) {
		const response = await api.get<UserModel>(`/users/${id}`);

		commit('userFetched', response);

		return response;
	}

	@loading()
	async createUser(ctx: Ctx, data: CreateUserPayload) {
		const response = await api.post<CreateResponse>('/users', data);

		return response;
	}

	@loading({ presets: { id: true } })
	async deleteUser(ctx: Ctx, id: number) {
		const response = await api.delete<DeleteResponse>(`/users/${id}`);

		return response;
	}

	@loading()
	async updateUser(ctx: Ctx, { id, ...data }: UpdateUserPayload) {
		const response = await api.put<UpdateResponse>(`/users/${id}`, omitBy(data, isUndefined));

		return response;
	}

	@loading()
	async searchUsers(ctx: Ctx, query: string) {
		const url = applyQuery(`/users`, { search: query });

		return await api.get(url);
	}
}

const actions = createStoreClassInstance(UserActions);
export const userActions = getActions(actions, 'user');

export default actions;
