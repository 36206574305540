import { schema, SchemaArray } from 'normalizr';

import { OrganisationModel, SimpleOrganisationModel } from '@/store/models/Organisation';

export const organisationSchema = new schema.Entity<OrganisationModel>('organisations');

export const simpleOrganisationSchema = new schema.Entity<SimpleOrganisationModel>('organisations');

export const organisationListSchema = new schema.Array(simpleOrganisationSchema);

export const pagedOrganisationListSchema = new schema.Object<SchemaArray<SimpleOrganisationModel>>({
	data: organisationListSchema,
});
