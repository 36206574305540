import { trackRouter } from 'vue-gtag-next';
import { createRouter, createWebHistory } from 'vue-router';

import { SubjectType } from '@/common/abilities';

import middlewares from './middlewares';
import routes from './routes';

declare module 'vue-router' {
	interface RouteMeta {
		abilitySubject?: SubjectType;
		headline?: string | ((order: string) => string);
		flow?: 'prosthetist';
		requireNoAuth?: boolean;
		forceRedirectAdmin?: boolean;
	}
}

export const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,

	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return { el: to.hash };
		} else if (savedPosition) {
			return savedPosition;
		} else {
			return { left: 0, top: 0 };
		}
	},
});

middlewares.forEach(middleware => router.beforeEach(middleware));
trackRouter(router);
