import { isString } from 'lodash';

import { ValidationRule } from '@/mixins/validation/validator';

export const noWhiteSpace: ValidationRule = value => {
	if (!isString(value) || value === '') {
		return true;
	}

	const isValid = (value as string).trim() !== '';

	return isValid || 'The field cannot contain only white signs';
};
