import { appAbility } from './ability';
import { ActionType, SubjectType } from './data';

export function canOneOf(action: ActionType, subjects: SubjectType[]): boolean {
	if (!subjects.length) {
		return true;
	}

	return subjects.reduce((acc: boolean, s) => acc || appAbility.can(action, s), false);
}
