import { isNumber } from 'lodash';

import { ValidationRule } from '@/mixins/validation/validator';

export const maxDecimalDigits: (max: number) => ValidationRule = max => value => {
	if (!isNumber(value)) {
		return true;
	}

	const decimalPart = Math.abs(value)
		.toString()
		.split('.')[1];
	const isValid = decimalPart ? decimalPart.length <= max : true;

	return isValid || `Only ${max} decimal digit${max === 1 ? '' : 's'} are allowed`;
};
