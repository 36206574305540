import { getGetters } from '@owlsdepartment/vuex-typed';

import { AddressModel } from '@/store/models/Address';

import { AddressState } from './state';

const getters = {
	addressListByName: (state: AddressState) => (name: string): Array<AddressModel> => {
		return state.lists[name]?.map((id: number) => state.addresses[id]).filter(a => !!a) ?? [];
	},

	addressListByUserId: (state: AddressState) => (id: number): Array<AddressModel> => {
		return getters.addressListByName(state)(`of_user_${id}`);
	},

	addressListByOrganisationId: (state: AddressState) => (id: number): Array<AddressModel> => {
		return getters.addressListByName(state)(`of_organisation_${id}`);
	},

	addressListDefault: (state: AddressState) => getters.addressListByName(state)('default'),

	getAddressById: (state: AddressState) => (id: number): AddressModel | null => {
		return state.addresses[id] || null;
	},
};

export type AddressGetters = typeof getters;
export const addressGetters = getGetters(getters, 'address');

export default getters;
