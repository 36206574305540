import { ActionContext, getActions } from '@owlsdepartment/vuex-typed';

import { moduleDecorators } from '@/common/loading';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';

import { Tm3dGetters } from './getters';
import { Tm3dMutations } from './mutations';
import { Tm3dState } from './state';
import { FetchUrlByOrderIdPayload } from './types';

const { loading } = moduleDecorators('tm3d');

type Ctx = ActionContext<Tm3dState, Tm3dGetters, Tm3dMutations>;

class Tm3dActions {
	@loading()
	async fetchUrlByOrderId({ commit }: Ctx, { order_id }: FetchUrlByOrderIdPayload) {
		const { url } = await api.get(`/tm3d/get-url-scheme/${order_id}`);

		commit('urlFetched', {
			order_id,
			url,
		});

		return { url };
	}
}

const actions = createStoreClassInstance(Tm3dActions);
export const { fetchUrlByOrderId } = getActions(actions, 'tm3d');

export default actions;
