import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import LayoutLanding from '@/layouts/LayoutLanding.vue';
import ViewForgotPassword from '@/views/ViewForgotPassword.vue';
import ViewLogin from '@/views/ViewLogin.vue';
import ViewResetPassword from '@/views/ViewResetPassword.vue';

export default createRouteNode({
	shared: {
		basePath: '/login',
		meta: {
			requireNoAuth: true,
			layout: {
				component: LayoutLanding,
				props: {},
			},
		},
	},

	routes: [
		{
			path: '/',
			name: 'login',
			component: ViewLogin,
		},
		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: ViewForgotPassword,
		},
		{
			path: '/reset-password/:token',
			name: 'reset-password',
			component: ViewResetPassword,
		},
	],
});
