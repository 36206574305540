import { defineComponent, PropType } from 'vue';

import { ApiErrors } from '@/store/types';
import { AnyObject } from '@/tools/types';

import { ValidationOptions, Validator } from './validator';

export const validatorKey = Symbol('validator_key');

// NOTE: nested root validators? :thonk:
// NOTE: asynchrounus validation?
function provideValidator() {
	const validator = new Validator();

	return defineComponent({
		provide() {
			return {
				[validatorKey]: validator,
			};
		},

		props: {
			model: {
				type: Object as PropType<AnyObject>,
				default: () => ({}),
			},

			errors: {
				type: Object as PropType<ApiErrors>,
				default: () => ({}),
			},
		},

		computed: {
			hasErrors(): boolean {
				let result = false;

				for (const { child } of Object.values(validator.children)) {
					if (child.errorBag.length) {
						result = true;
					}
				}

				return result;
			},

			isValid(): boolean {
				return !this.hasErrors;
			},
		},

		watch: {
			errors: {
				handler(newErrors: ApiErrors) {
					this.addErrors(newErrors);
				},
				deep: true,
			},
		},

		created() {
			validator.parent = this;
		},

		methods: {
			addErrors(errorsDict: ApiErrors) {
				for (const [key, messages] of Object.entries(errorsDict)) {
					validator.children[key]?.child.errorBag.splice(0, 0, ...messages);
				}
			},

			validate(options?: ValidationOptions): boolean {
				return validator.validate(options);
			},
		},
	});
}

export default provideValidator;
