import { defineGetters } from '@owlsdepartment/vuex-typed';

import { SocketModel } from '@/store/models/Socket';

import state, { SocketState } from './state';

const getters = defineGetters(state, {
	socketListByName: (state: SocketState) => (name: string): Array<SocketModel> => {
		return state.lists[name]?.map(id => state.sockets[id]) ?? [];
	},
	socketListByProsthesisId: (state, getters) => (id: number): Array<SocketModel> => {
		return getters.socketListByName(`prosthesis_${id}`);
	},
	socketListDefault: (state, getters): Array<SocketModel> => getters.socketListByName()('default'),
	getSocketById: (state: SocketState) => (id: number): SocketModel | undefined => {
		return state.sockets[id];
	},
});

export type SocketGetters = typeof getters;
export default getters;
