import { schema, SchemaArray } from 'normalizr';

import { UserModel } from '@/store/models/User';

export const userSchema = new schema.Entity<UserModel>('users');

export const userListSchema = new schema.Array(userSchema);

export const pagedUserListSchema = new schema.Object<SchemaArray<UserModel>>({
	data: userListSchema,
});
