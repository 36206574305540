import { getState } from '@owlsdepartment/vuex-typed';

import { SkinGloveModel } from '@/store/models/SkinGlove';
import { ListOf } from '@/store/types';

const state = () => ({
	skinGloves: {} as ListOf<SkinGloveModel>,
	lists: {} as ListOf<Array<number>>,
});

export type SkinGloveState = ReturnType<typeof state>;
export const { skinGloves, lists } = getState(state, 'skinGlove');

export default state;
