import { defineComponent } from 'vue';

import { validatorKey } from './provideValidator';
import { Validator } from './validator';

export default defineComponent({
	inject: {
		$_validator: {
			from: validatorKey,
			default: null,
		},
	},

	computed: {
		validator(): Validator {
			return (this as any)['$_validator'] as Validator;
		},
	},
});
