import { onUnmounted } from 'vue';

export function useOnWindowEvent<Event extends keyof WindowEventMap>(
	event: Event,
	listener: (ev: WindowEventMap[Event]) => any,
) {
	window.addEventListener(event, listener);

	onUnmounted(() => {
		window.removeEventListener(event, listener);
	});
}
