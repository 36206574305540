import { OrderStep } from '../constants';

export enum OrderSection {
	TypeOfProsthesis = 1,
	Customize,
	ClientsDetails,
	OrderPreview,
	AddingScan,
	Alignment,
	Files,
	ShippingAddress,
	OrderFinalise,
}

export const orderSections: Record<OrderSection, OrderStep[]> = {
	[OrderSection.TypeOfProsthesis]: [OrderStep.New],
	[OrderSection.Customize]: [
		OrderStep.Terminal,
		OrderStep.Socket,
		OrderStep.ExtraParameters,
		OrderStep.ColorPattern,
		OrderStep.SkinGlove,
		OrderStep.Elbow,
	],
	[OrderSection.ClientsDetails]: [OrderStep.ClientDetails],
	[OrderSection.OrderPreview]: [OrderStep.Preview],
	[OrderSection.AddingScan]: [OrderStep.AddingScan],
	[OrderSection.Alignment]: [OrderStep.Alignment],
	[OrderSection.Files]: [OrderStep.Files],
	[OrderSection.ShippingAddress]: [OrderStep.ShippingAddress],
	[OrderSection.OrderFinalise]: [OrderStep.Finalise],
};

export namespace OrderSection {
	// eslint-disable-next-line no-inner-declarations
	export function findSection(step: OrderStep): OrderSection | null {
		const entry = Object.entries(orderSections).find(([, steps]) => steps.includes(step));

		if (!entry) {
			return null;
		}

		return +entry[0];
	}
}
