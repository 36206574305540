import { getGetters } from '@owlsdepartment/vuex-typed';

import { ColorPatternModel } from '@/store/models/ColorPattern';

import { ColorPatternState } from './state';

const getters = {
	colorPatternListByName: (state: ColorPatternState) => (
		name: string,
	): Array<ColorPatternModel> => {
		return state.lists[name]?.map((id: number) => state.colorPatterns[id]) ?? [];
	},
	colorPatternListByProsthesisId: (state: ColorPatternState) => (
		id: number,
	): Array<ColorPatternModel> => {
		return getters.colorPatternListByName(state)(`prosthesis_${id}`);
	},
	colorPatternListDefault: (state: ColorPatternState) =>
		getters.colorPatternListByName(state)('default'),
	getColorPatternById: (state: ColorPatternState) => (
		id: number,
	): ColorPatternModel | undefined => {
		return state.colorPatterns[id];
	},
};

export type ColorPatternGetters = typeof getters;
export const {
	colorPatternListByName,
	colorPatternListByProsthesisId,
	colorPatternListDefault,
	getColorPatternById,
} = getGetters(getters, 'colorPattern');
export default getters;
