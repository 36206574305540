import { defineMutations } from '@owlsdepartment/vuex-typed';
import { RouteLocationNormalized } from 'vue-router';

import state from './state';

export default defineMutations(state, {
	setGlobalLoader(state, isLoading: boolean) {
		state.showGlobalLoader = isLoading;
	},

	setPreviousRoute(state, prev: RouteLocationNormalized) {
		state.previousRoute = prev;
	},
});
