import { ActionContext, getActions } from '@owlsdepartment/vuex-typed';
import { normalize } from 'normalizr';

import { moduleDecorators } from '@/common/loading';
import { SkinGloveModel } from '@/store/models/SkinGlove';
import { ListOf } from '@/store/types';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';

import { SkinGloveGetters } from './getters';
import { SkinGloveMutations } from './mutations';
import { skinGloveList as skinGloveListShema } from './schemas';
import { SkinGloveState } from './state';
import { FetchSkinGloveListPayload } from './types';

const { loading } = moduleDecorators('skinGlove');

type Ctx = ActionContext<SkinGloveState, SkinGloveGetters, SkinGloveMutations>;

class SkinGloveActions {
	@loading()
	async fetchSkinGloveList({ commit }: Ctx, { prosthesis_id }: FetchSkinGloveListPayload) {
		const response = await api.get(`/skin-gloves`);

		const name = `prosthesis_${prosthesis_id}`;
		const { entities, result } = normalize(response, skinGloveListShema);

		commit('skinGlovesFetched', entities as { skinGloves: ListOf<SkinGloveModel> });
		commit('skinGloveListFetched', { name, data: result });

		return response;
	}

	@loading()
	async fetchSkinGloveById({ commit }: Ctx, skin_glove_id: number) {
		const response = await api.get(`/skin-gloves/${skin_glove_id}`);
		const skinGlove = response as SkinGloveModel;

		commit('skinGloveFetched', { skinGlove });

		return skinGlove;
	}
}

const actions = createStoreClassInstance(SkinGloveActions);
export const { fetchSkinGloveList, fetchSkinGloveById } = getActions(actions, 'skinGlove');

export default actions;
