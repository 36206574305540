import { ActionContext, getActions } from '@owlsdepartment/vuex-typed';
import { normalize } from 'normalizr';

import { moduleDecorators } from '@/common/loading';
import { ColorPatternModel } from '@/store/models/ColorPattern';
import { ListOf } from '@/store/types';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';

import { ColorPatternGetters } from './getters';
import { ColorPatternMutations } from './mutations';
import { colorPatternList as colorPatternListShema } from './schemas';
import { ColorPatternState } from './state';
import { FetchColorPatternListPayload } from './types';

const { loading } = moduleDecorators('colorPattern');

type Ctx = ActionContext<ColorPatternState, ColorPatternGetters, ColorPatternMutations>;

class ColorPatternActions {
	@loading()
	async fetchColorPatternList({ commit }: Ctx, { prosthesis_id }: FetchColorPatternListPayload) {
		const response = await api.get(`/color-pattern/type/${prosthesis_id}`);

		const name = `prosthesis_${prosthesis_id}`;
		const { entities, result } = normalize(response, colorPatternListShema);

		commit('colorPatternsFetched', entities as { colorPatterns: ListOf<ColorPatternModel> });
		commit('colorPatternListFetched', { name, data: result });

		return response;
	}

	@loading()
	async fetchColorPatternById({ commit }: Ctx, color_pattern_id: number) {
		const response = await api.get(`/color-pattern/${color_pattern_id}`);
		const colorPattern = response as ColorPatternModel;

		commit('colorPatternFetched', { colorPattern });

		return colorPattern;
	}
}

const actions = createStoreClassInstance(ColorPatternActions);
export const { fetchColorPatternList, fetchColorPatternById } = getActions(actions, 'colorPattern');

export default actions;
