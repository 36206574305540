import { Ref, ref } from 'vue';

export class Popup {
	protected _isOpen: Ref<boolean>;

	get isOpen(): boolean {
		return this._isOpen.value;
	}

	set isOpen(val: boolean) {
		this._isOpen.value = val;
	}

	constructor(isOpen = false) {
		this._isOpen = ref(isOpen);
	}

	open() {
		this.isOpen = true;
	}

	close() {
		this.isOpen = false;
	}
}
