import { getState } from '@owlsdepartment/vuex-typed';

import { AddressModel } from '@/store/models/Address';
import { ListOf } from '@/store/types';

const state = () => ({
	addresses: {} as ListOf<AddressModel>,
	lists: {} as ListOf<Array<number>>,
});

export type AddressState = ReturnType<typeof state>;
export const addressState = getState(state, 'address');

export default state;
