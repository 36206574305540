import { defineModule, getModule } from '@owlsdepartment/vuex-typed';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

const module = defineModule({
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
});

export const {
	state: socketState,
	getters: socketGetters,
	mutations: socketMutations,
	actions: socketActions,
} = getModule(module, 'socket');

export default module;
