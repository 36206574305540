import { SocketModel } from '@/store/models/Socket';
import { ListOf } from '@/store/types';

const state = () => ({
	sockets: {} as ListOf<SocketModel>,
	lists: {} as ListOf<Array<number>>,
});

export type SocketState = ReturnType<typeof state>;
export default state;
