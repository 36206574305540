import { Dictionary } from 'lodash';

export function applyQuery(url: string, queryDict: Dictionary<any>): string {
	const querys = [];

	for (const key in queryDict) {
		if (queryDict[key] !== '') {
			querys.push(`${key}=${queryDict[key]}`);
		}
	}

	return `${url}?${querys.join('&')}`;
}
