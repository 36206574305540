const preloadImageCache = {} as any;

export function preloadImage(src: string): Promise<HTMLImageElement> {
	if (preloadImageCache[src]) {
		return preloadImageCache[src];
	}
	const promise = new Promise(resolve => {
		const img = document.createElement('img');
		img.onload = () => resolve(img);
		img.src = src;
	}) as Promise<HTMLImageElement>;

	preloadImageCache[src] = promise;
	return promise;
}
