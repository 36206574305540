<template>
	<div class="sidebar-dashboard">
		<div class="sidebar-dashboard__container">
			<div class="sidebar-dashboard__header">
				<h2 class="sidebar-dashboard__heading">Contact details</h2>
				<p class="sidebar-dashboard__description">
					If you have any questions,
					<br />
					please contact us:
					<br />
					hello@glazeprosthetics.com
				</p>
			</div>

			<div class="sidebar-dashboard__buttons">
				<base-button :to="{ name: 'order-new' }" class="sidebar-dashboard__button">
					<template #icon>
						<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 1.5v35M1.5 19h35" stroke="#201E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
					</template>
					New Order
				</base-button>

				<base-button :to="{ name: 'drafts-add-scan' }" class="sidebar-dashboard__button">
					<template #icon>
						<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 1.5v35M1.5 19h35" stroke="#201E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
					</template>
					Add Scan
				</base-button>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.sidebar-dashboard {
	$height: calc(#{vh(100)} - #{$header-height});

	width: 100%;
	height: $height;
	position: relative;

	@include max-md-and-portrait {
		height: auto;
	}

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: $height;
		padding-top: 12.8rem;
		padding-bottom: 11.8rem;
		position: fixed;
		overflow-y: scroll;
		overscroll-behavior-y: contain;

		@include max-md-and-portrait {
			padding-top: 8rem;
			padding-bottom: 0;
			position: static;
		}

		@include max-tablet-portrait {
			height: auto;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__header {
		padding-right: 4.8rem;

		@include max-xl {
			width: 23rem;
		}

		@include max-lg-and-portrait {
			padding-right: 0;
		}
	}

	&__heading {
		@include heading-2;
		margin-bottom: 2.4rem;
	}

	&__description {
		@include small-text;
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: auto;
		margin-bottom: 0;

		@include max-lg-and-portrait {
			width: 23rem;
			margin-top: 5rem;
		}

		@include max-md-and-portrait {
			display: grid;
			grid-column-gap: 1.6rem;
			grid-template-columns: 1fr 1fr;
			min-width: 100%;
			margin-top: 7rem;
		}
	}

	&__button {
		@include heading-3;
		width: 100%;
		margin: 1.2rem 0;

		@include max-lg-and-portrait {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		@include min-xl {
			width: 26rem;
			height: 8rem;
		}

		&.base-button {
			justify-content: flex-start;
			padding: 1.6rem 2rem;

			@include max-xl {
				font-size: 2.4rem;
			}

			@include max-lg-and-portrait {
				padding: 2rem;
				font-size: 2.4rem;
			}
		}

		.base-button__icon + .base-button__text {
			margin-left: 2.6rem;
			line-height: 1.24;

			@include max-lg-and-portrait {
				margin-left: 2.7rem;
				font-size: 2.4rem;
			}
		}
	}
}
</style>
