import { getMutations } from '@owlsdepartment/vuex-typed';

import { OrganisationModel, SimpleOrganisationModel } from '@/store/models/Organisation';
import { ListOf, PagedListFetched } from '@/store/types';

import { OrganisationState } from './state';

const mutations = {
	organisationFetched(
		state: OrganisationState,
		{ organisation }: { organisation: OrganisationModel },
	) {
		state.organisations[organisation.id] = organisation;
	},

	organisationsFetched(
		state: OrganisationState,
		{ organisations }: { organisations: ListOf<OrganisationModel> },
	) {
		for (const k in organisations) {
			state.organisations[organisations[k].id] = organisations[k];
		}
	},

	simpleOrganisationFetched(
		state: OrganisationState,
		{ organisation }: { organisation: SimpleOrganisationModel },
	) {
		state.simpleOrganisations[organisation.id] = organisation;
	},

	allSimpleOrganisationsFetched(state: OrganisationState, data: number[]) {
		state.allSimpleOrganizations = data;
	},

	simpleOrganisationsFetched(
		state: OrganisationState,
		{ organisations }: { organisations: ListOf<SimpleOrganisationModel> },
	) {
		for (const k in organisations) {
			state.simpleOrganisations[organisations[k].id] = organisations[k];
		}
	},

	organisationListFetched(state: OrganisationState, { name, data }: PagedListFetched) {
		state.lists[name] = data as any;
	},

	organisationListReset(state: OrganisationState, { name }: { name: string }) {
		state.lists[name] = [] as any;
	},

	organisationDeleted(state: OrganisationState, id: number) {
		delete state.organisations[id];
	},
};

export type OrganisationMutations = typeof mutations;
export const organisationMutations = getMutations(mutations, 'organisation');

export default mutations;
