import { Directive } from 'vue';

const preloaderDiv = Symbol('preloaderDiv');
const hasPreloaderFlag = Symbol('hasPreloaderFlag');

interface PreloaderElement extends HTMLElement {
	[preloaderDiv]?: HTMLElement;
	[hasPreloaderFlag]?: boolean;
}

function removePreloader(el: PreloaderElement) {
	if (el[preloaderDiv] !== undefined && el[hasPreloaderFlag] === true) {
		el.removeChild(el[preloaderDiv] as HTMLElement);
		el[hasPreloaderFlag] = false;
		el.classList.remove('has-preloader');
	}
}

function addPreloader(el: PreloaderElement) {
	if (el[preloaderDiv] === undefined) {
		el[preloaderDiv] = createPreloader();
	}

	if (el[hasPreloaderFlag] !== true) {
		el.appendChild(el[preloaderDiv] as HTMLElement);
		el[hasPreloaderFlag] = true;
		el.classList.add('has-preloader');
	}
}

function createPreloader(): HTMLElement {
	const preloader = document.createElement('div');
	preloader.className = 'preloader';
	preloader.innerHTML = `
		<div class="preloader__inner">
			<div class="preloader__spinner preloader-spinner">
				<div class="preloader-spinner__helper"></div>
			</div>
		</div>
		`;

	return preloader;
}

export const preloader: Directive = {
	beforeMount(el: HTMLElement, { value }) {
		if (value) {
			addPreloader(el);
		}
	},

	updated(el: HTMLElement, { value }) {
		value ? addPreloader(el) : removePreloader(el);
	},

	unmounted(el: HTMLElement) {
		removePreloader(el);
	},
};
