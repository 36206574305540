import { Component } from 'vue';

import { AnyObject } from '@/tools/types';

import { Popup } from './Popup';

export interface ManualPopupConfig {
	props?: AnyObject;
	isOpen?: boolean;
	addTeleport?: boolean;
}

export class ManualPopup extends Popup {
	addTeleport: boolean;

	props: AnyObject;

	constructor(
		public component: Component,
		{ props = {}, isOpen = false, addTeleport = false }: ManualPopupConfig = {},
	) {
		super(isOpen);

		this.props = props;
		this.addTeleport = addTeleport;
	}
}
