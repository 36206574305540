import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import { Subject } from '@/common/abilities';
import LayoutAdmin from '@/layouts/LayoutAdmin.vue';
import { passParamsToProps } from '@/router/helpers';

const ViewAdminAddressesCreate = () =>
	import('@/views/admin/addresses/ViewAdminAddressesCreate.vue');
const ViewAdminAddressesUpdate = () =>
	import('@/views/admin/addresses/ViewAdminAddressesUpdate.vue');

export default createRouteNode({
	shared: {
		basePath: '/addresses',
		meta: {
			abilitySubject: Subject.ADMIN_PANEL,
			layout: {
				component: LayoutAdmin,
			},
		},
	},

	routes: [
		{
			name: 'admin-addresses-create',
			path: '/organisation/:organisationId/create',
			component: ViewAdminAddressesCreate,
			props: passParamsToProps({ organisationId: Number }),
		},
		{
			name: 'admin-addresses-update',
			path: '/:addressId/edit',
			component: ViewAdminAddressesUpdate,
			props: passParamsToProps({ addressId: Number }),
		},
	],
});
