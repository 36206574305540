import { Dictionary, isString } from 'lodash';
import { nextTick } from 'vue';

import { AnyObject } from '@/tools/types';
import { scrollToError } from '@/tools/validation';

// NOTE: maybe better return api? object?
export type ValidationRule<V = any, T extends AnyObject = AnyObject> = (
	value: V,
	model: T,
	options: ValidationOptions,
) => true | string;

export interface ValidationOptions {
	omitRequired?: boolean;
	preventScrolling?: boolean;
}

export interface ValidatorComponent {
	model: AnyObject;
}

export interface ValidatorChild {
	rules: ValidationRule[];
	errorBag: string[];
	reset(): void;
}

type ModelProvider = () => AnyObject | undefined | null;
type MaybeModelProvider = ModelProvider | undefined | null;
export class Validator {
	children: Dictionary<{ child: ValidatorChild; modelProvider: MaybeModelProvider }> = {};

	constructor(public parent?: ValidatorComponent) {}

	addChild(key: string, child: ValidatorChild, modelProvider: MaybeModelProvider): void {
		this.children[key] = {
			child,
			modelProvider,
		};
	}

	removeChild(key: string, child: ValidatorChild): void {
		if (this.children[key]?.child === child) {
			delete this.children[key];
		}
	}

	validate(options: ValidationOptions = {}): boolean {
		let valid = true;

		for (const key of Object.keys(this.children)) {
			if (!this.validateField(key, options)) {
				valid = false;
			}
		}

		if (!valid && !options.preventScrolling) {
			nextTick().then(() => {
				scrollToError();
			});
		}

		return valid;
	}

	validateField(key: string, options: ValidationOptions = {}): boolean {
		const child = this.children[key]?.child;
		const model = this.children[key].modelProvider?.() ?? this.parent?.model;

		if (!child) {
			throw `[Validator] Field under key '${key}' wasn't registered`;
		}
		if (!model) {
			throw `[Validator] Model wasn't initialized! Received: '${model}'`;
		}

		const value = extractValue(key, model);
		const { rules } = child;

		child.errorBag = [];

		for (const rule of rules) {
			const output = rule(value, model, options);

			if (output !== true) {
				if (isString(output)) {
					child.errorBag.push(output);
				} else {
					throw `[Validator] Value returned from function should be of type 'true' or 'string'.
							Received ${typeof value} instead`;
				}
			}
		}

		return !child.errorBag.length;
	}

	reset(): void {
		for (const { child } of Object.values(this.children)) {
			child.reset();
		}
	}
}

function extractValue(key: string, root?: Dictionary<any>): any {
	const keys = key.split('.');
	const valueKey = keys.pop();

	if (valueKey === undefined) {
		return;
	}

	for (const partialKey of keys) {
		root = root?.[partialKey];
	}

	return root?.[valueKey];
}
