import { defineComponent, PropType } from 'vue';

import { AnyObject } from '@/tools/types';

import injectValidator from './injectValidator';
import { ValidationOptions, ValidationRule } from './validator';

export const validatorOverrideModelKey = Symbol('validator_override_model_key');

type EventListener = (this: Element, ev: Event) => any;

const VALIDATION_EVENTS = ['change', 'blur', 'input'];

export default defineComponent({
	mixins: [injectValidator],

	inject: {
		$_overrideModel: {
			from: validatorOverrideModelKey,
			default: null,
		},
	},

	props: {
		name: {
			type: String,
			default: '',
		},

		rules: {
			type: Array as () => ValidationRule[],
			default: () => [],
		},

		validationModel: {
			type: Object as PropType<AnyObject>,
			required: false,
			default: null,
		},
	},

	data() {
		return {
			errorBag: new Array<string>(),
			inputElement: null as Element | null,
		};
	},

	computed: {
		hasError(): boolean {
			return !!this.errorBag.length;
		},

		overrideModel(): AnyObject | null {
			const overrideModel = (this as any)['$_overrideModel'];
			const { validationModel } = this;

			return validationModel ?? overrideModel ?? null;
		},
	},

	watch: {
		name(newName, oldName) {
			if (oldName) {
				this.validator.removeChild(oldName, this);
			}
			if (newName) {
				this.validator.addChild(newName, this, () => this.overrideModel);
			}
		},
	},

	created() {
		if (this.name) {
			this.validator?.addChild(this.name, this, () => this.overrideModel);
		}
	},

	mounted() {
		this._manageListeners('add', VALIDATION_EVENTS, this.validateOnEvent);
	},

	beforeUnmount() {
		this._manageListeners('remove', VALIDATION_EVENTS, this.validateOnEvent);

		if (this.name) {
			this.validator?.removeChild(this.name, this);
		}
	},

	methods: {
		_manageListeners(action: 'add' | 'remove', events: string[], callback: EventListener) {
			const method = action === 'add' ? 'addEventListener' : 'removeEventListener';

			for (const event of events) {
				this.$el?.[method](event, callback);
			}
		},

		validate(options?: ValidationOptions): boolean {
			if (!this.name || !this.rules.length) {
				return true;
			}

			return this.validator.validateField(this.name, options);
		},

		validateOnEvent() {
			this.validate();
		},

		reset(): void {
			this.errorBag = [];
		},

		addError(errorMessages: string[]): void {
			this.errorBag.splice(0, 0, ...errorMessages);
		},
	},
});
