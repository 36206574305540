import { AmputationType } from '@/components/customer/details/CustomerDetailsForm.vue';
import { OrderStep } from '@/constants/order/constants';
import { OrderModel } from '@/store/models/Order';
import { MeasurementUnit, OrderDetailsMeasurementsModel } from '@/store/models/OrderDetails';
import { SocketBattery, SocketElectrodes } from '@/store/models/Socket';
import { PaginatedPayload } from '@/store/types';

export type OrderStatusAccepted =
	| 'accepted'
	| 'accepted_in_progress'
	| 'accepted_in_delivery'
	| 'accepted_delivered';

export type OrderStatusRejected =
	| 'rejected'
	| 'rejected_incorrect_scan'
	| 'rejected_incorrect_attachments'
	| 'rejected_incorrect_measurements';

export type OrderStatusOther = 'drafted' | 'placed' | 'archived';

export type OrderStatus = OrderStatusAccepted | OrderStatusRejected | OrderStatusOther;

export function isOrderRejected(value?: OrderStatus): value is OrderStatus {
	if (!value) return false;

	return [
		'rejected',
		'rejected_incorrect_scan',
		'rejected_incorrect_attachments',
		'rejected_incorrect_measurements',
	].includes(value as OrderStatus);
}

export interface CreateOrderPayload {
	prosthesis_id?: number;
	step: OrderStep;
	terminal_device_only?: boolean;
}

export interface UpdateOrderPayload {
	id: number;
	step: OrderStep;
	guid: string;
	address_id?: number;
}

export interface ChangeOrderStatusPayload {
	id: number;
	status: OrderStatus;
}

export interface FetchUserOrderListPayload {
	status: 'placed' | 'ready' | 'drafted';
	page?: number | null;
	perPage?: number | null;
}

export interface FetchOrdersListPayload extends PaginatedPayload {
	status: OrderStatus;
}

export interface OrdersStatusCount {
	accepted?: number;
	archived?: number;
	draft?: number;
	placed?: number;
	rejected?: number;
}

interface WithGuid {
	guid: string;
}

interface WithStep {
	step: OrderStep;
}

export interface UpdateTerminalPayload extends WithStep, WithGuid {
	terminal_id: number[];
	terminals: Array<{
		terminal_id: number;
		terminal_glove_variant_id?: number | null;
		terminals_option_id?: number | null;
		special_finishing?: boolean | null;
		email?: string | null;
	}>;
}

export interface UpdateSocketPayload extends WithStep, WithGuid {
	bionic_hand_id: number;
	buying_option?: string;
	warranty?: string;
	color?: string;
	size?: string;
	notes?: string;
	electrodes?: SocketElectrodes;
	battery?: SocketBattery;
}

export interface UpdateColorPatternsPayload extends WithStep, WithGuid {
	color_pattern_id: number;
	special_finishing: boolean;
	special_finishing_description: string | null;
}

export interface UpdateSkinGlovePayload extends WithStep, WithGuid {
	skin_glove_id: number;
	skin_glove_details: {
		catalog_number: string;
		size: string;
		color: string;
		notes: string;
	};
}

export interface UpdateElbowPayload extends WithStep, WithGuid {
	elbow_id: number;
}

export interface UpdateOrderDetailsPayload extends WithStep, WithGuid {
	order_details: {
		unit_of_measure: MeasurementUnit;
		measurements: OrderDetailsMeasurementsModel;
		body_side: string;
		clients_name: string;
		amputation_type?: AmputationType;
		additional_notes?: string;
		already_owned: boolean;
		valve_opening?: boolean;
	};
}

export interface UpdateScansPayload extends WithStep, WithGuid {
	frontal_angle?: number;
	sagittal_angle?: number;
	scanner_type?: string | null;
}

export interface UpdatePhotosPayload extends WithStep, WithGuid {
	photos: File[];
}

export type UpdateOrderModel = { guid: string } & Partial<OrderModel>;

export interface SearchOrdersPayload {
	query: string;
	status: OrderStatus;
}
