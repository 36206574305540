/* eslint-disable no-inner-declarations */
import { OrderModel } from '@/store/models/Order';
import { OrderStatusRejected } from '@/store/modules/order/types';
import { Comparator, compare } from '@/tools/compare';

import { ProsthesisType } from '../prosthesis/constants';

export enum OrderStep {
	New = 'order-new',
	Terminal = 'terminal-customization',
	Socket = 'socket-customization',
	ExtraParameters = 'extra-parameters-customization',
	ColorPattern = 'color-pattern-customization',
	SkinGlove = 'skin-glove-customization',
	Elbow = 'elbow-customization',
	ClientDetails = 'order-clients-details',
	Preview = 'order-preview',
	AddingScan = 'order-scan',
	Alignment = 'order-alignment',
	Files = 'order-files',
	ShippingAddress = 'order-shipping-address',
	Finalise = 'order-finalise',
}

const sharedPath: OrderStep[] = [
	OrderStep.ClientDetails,
	OrderStep.Preview,
	OrderStep.AddingScan,
	OrderStep.Alignment,
	OrderStep.Files,
	OrderStep.ShippingAddress,
	OrderStep.Finalise,
];

const sharedPathShort: OrderStep[] = [
	OrderStep.ClientDetails,
	OrderStep.Preview,
	OrderStep.ShippingAddress,
	OrderStep.Finalise,
];

export const orderPath: Record<ProsthesisType, OrderStep[]> = {
	[ProsthesisType.GlazeOneBelow]: [OrderStep.ColorPattern, OrderStep.Terminal, ...sharedPath],
	[ProsthesisType.GlazeKidsBelow]: [OrderStep.ColorPattern, OrderStep.Terminal, ...sharedPath],
	[ProsthesisType.GlazeCosmetics]: [OrderStep.SkinGlove, ...sharedPath],
	[ProsthesisType.WhizzlinkBelow]: [
		OrderStep.ColorPattern,
		OrderStep.Socket,
		OrderStep.ExtraParameters,
		...sharedPath,
	],
	[ProsthesisType.WhizzlinkKidsBelow]: [OrderStep.ColorPattern, OrderStep.Terminal, ...sharedPath],
	[ProsthesisType.WhizzlinkEcoBelow]: [OrderStep.ColorPattern, OrderStep.Terminal, ...sharedPath],
	[ProsthesisType.GlazeOneAbove]: [
		OrderStep.ColorPattern,
		OrderStep.Terminal,
		OrderStep.Elbow,
		...sharedPathShort,
	],
	[ProsthesisType.WhizzlinkAbove]: [
		OrderStep.ColorPattern,
		OrderStep.Socket,
		OrderStep.Elbow,
		OrderStep.ExtraParameters,
		...sharedPathShort,
	],
	[ProsthesisType.WhizzlinkEcoAbove]: [
		OrderStep.ColorPattern,
		OrderStep.Terminal,
		OrderStep.Elbow,
		...sharedPathShort,
	],
	[ProsthesisType.NullProsthesis]: [
		OrderStep.Terminal,
		OrderStep.ClientDetails,
		OrderStep.ShippingAddress,
		OrderStep.Finalise,
	],
};

export const rejectedOrderEditSteps: Record<OrderStatusRejected, OrderStep[]> = {
	rejected: [OrderStep.Preview],
	rejected_incorrect_scan: [OrderStep.AddingScan],
	rejected_incorrect_measurements: [OrderStep.ClientDetails],
	rejected_incorrect_attachments: [OrderStep.Files],
};

export namespace OrderStep {
	export function compareStepToOrder(
		step: OrderStep,
		comparator: Comparator,
		order: OrderModel,
	): boolean {
		const path = orderPath[order.prosthesis.slug];
		const orderStep = order.step;
		const stepIndex = path.findIndex(val => val === step);
		const orderStepIndex = path.findIndex(val => val === orderStep);

		return compare(stepIndex, comparator, orderStepIndex);
	}

	export function getNextStep(step: OrderStep, order?: OrderModel): OrderStep | null {
		if (!order) {
			return null;
		}

		const path = orderPath[order.prosthesis.slug];
		const currentIndex = path.findIndex(val => val === step);

		if (currentIndex === -1 || currentIndex === path.length - 1) {
			return null;
		}

		return path[currentIndex + 1];
	}

	export function getPreviousStep(step: OrderStep, order?: OrderModel): OrderStep | null {
		if (!order) {
			return null;
		}

		const path = orderPath[order.prosthesis.slug];
		const currentIndex = path.findIndex(val => val === step);

		if (currentIndex === -1 || currentIndex === 0) {
			return null;
		}

		return path[currentIndex - 1];
	}
}

export const orderDetailsComponent: Record<ProsthesisType, string> = {
	[ProsthesisType.GlazeOneBelow]: 'DefaultDetails',
	[ProsthesisType.GlazeKidsBelow]: 'DefaultDetails',
	[ProsthesisType.GlazeCosmetics]: 'GlazeCosmeticsDetails',
	[ProsthesisType.WhizzlinkBelow]: 'WhizzlinkDetails',
	[ProsthesisType.WhizzlinkKidsBelow]: 'DefaultDetails',
	[ProsthesisType.WhizzlinkEcoBelow]: 'DefaultDetails',

	[ProsthesisType.GlazeOneAbove]: 'DefaultDetails',
	[ProsthesisType.WhizzlinkAbove]: 'WhizzlinkDetails',
	[ProsthesisType.WhizzlinkEcoAbove]: 'DefaultDetails',
	[ProsthesisType.NullProsthesis]: 'DefaultDetails',
};

export const NULL_PROSTHESIS_ID = 10;

export const TERMINAL_DEVICE_ONLY_HIDE_COLOR_VARIANTS = ['according-to-chosen-color-combination'];
export const PROSTHETIST_CATEGORY_HIDE_COLOR_VARIANTS = [
	'cloudy-billy',
	'red-robin',
	'powder-pink',
	'bubble-gum',
	'jumbo-jungle',
	'polar-bear',
	'lolli-pop',
];
