import { UserType } from '@/constants/users/constants';

import { appAbility } from './ability';
import { RULES_MAP } from './rules';

export function updateUserAbilities(userType: UserType | string = '') {
	const rules = userType in UserType ? RULES_MAP[userType as UserType] : [];

	appAbility.update(rules);
}
