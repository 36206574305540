type ToReturn<T, E = Error> = Promise<[E | null, T | undefined]>;

export async function to<T, E = Error>(promise: Promise<T>): ToReturn<T, E> {
	try {
		return [null, await promise];
	} catch (err) {
		if (err === null || err === undefined) {
			// eslint-disable-next-line no-ex-assign
			err = new Error();
		}

		return [err as E, undefined];
	}
}
