import gsap from 'gsap';
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},

		canSelectMore: {
			type: Boolean,
			default: false,
		},
	},

	emits: ['remove', 'select-more', 'update:modelValue'],

	methods: {
		removeItem(index: number) {
			this.$emit('remove', index);
		},

		selectMore() {
			this.$emit('select-more');
		},

		beforeEnter(el: Element) {
			if (!(el instanceof HTMLElement)) return;

			if (el.dataset.index !== undefined) {
				gsap.set(el, { autoAlpha: 0, y: 20, pointerEvents: 'none' });
			} else {
				gsap.set(el, { autoAlpha: 0 });
			}
		},

		enter(el: Element, onComplete: () => void) {
			if (!(el instanceof HTMLElement)) return;

			gsap.to(el, {
				duration: 0.8,
				autoAlpha: 1,
				x: 0,
				y: 0,
				pointerEvents: 'initial',
				delay: (el.dataset.index as any) * 0.15,
				ease: 'power4.out',
				onComplete,
			});
		},
	},
});
