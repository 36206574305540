import { getGetters } from '@owlsdepartment/vuex-typed';

import { NotificationModel } from '@/store/models/Notification';

import { NotificationState } from './state';

const getters = {
	getNotificationById: (state: NotificationState) => (id: string): NotificationModel | null =>
		state.notifications[id],

	notificationListByName: (state: NotificationState) => (
		name: string,
	): Array<NotificationModel> => {
		return state.lists[name]?.map((id: string) => state.notifications[id]) ?? [];
	},

	notificationListByUserIdAndStatus: (state: NotificationState) => (
		id: number,
		status = 'unread',
	): Array<NotificationModel> => {
		return getters.notificationListByName(state)(`user_${id}_status_${status}`);
	},
};

export type NotificationGetters = typeof getters;
export const notificationGetters = getGetters(getters, 'notification');

export default getters;
