<template>
	<div :class="className" />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	setup() {
		return {
			className: 'vue-content-placeholders-img',
		};
	},
});
</script>
