import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import SidebarDashboard from '@/components/sidebar/SidebarDashboard.vue';
import LayoutDefault from '@/layouts/LayoutDefault.vue';

const ViewDashboard = () => import('@/views/ViewDashboard.vue');

export default createRouteNode({
	shared: {
		basePath: '/dashboard',
		meta: {
			layout: {
				component: LayoutDefault,
				props: {
					sidebar: {
						small: true,
						sticky: false,
						component: SidebarDashboard,
					},
				},
			},
		},
	},

	routes: [
		{
			path: '/',
			name: 'dashboard',
			component: ViewDashboard,
		},
	],
});
