import { getMutations } from '@owlsdepartment/vuex-typed';

import { Tm3dState } from './state';

const mutations = {
	urlFetched(state: Tm3dState, { order_id, url }: { order_id: number; url: string }) {
		state.urls[order_id] = url;
	},
};

export type Tm3dMutations = typeof mutations;
export const { urlFetched } = getMutations(mutations, 'tm3d');

export default mutations;
