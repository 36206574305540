import { getGetters } from '@owlsdepartment/vuex-typed';

import { OrganisationModel, SimpleOrganisationModel } from '@/store/models/Organisation';
import { ApiCollection, Meta } from '@/store/types';

import { OrganisationState } from './state';

const getters = {
	getOrganisationById: (state: OrganisationState) => (id: number): OrganisationModel | null =>
		state.organisations[id],

	organisationListByName: (state: OrganisationState) => (
		name: string,
	): ApiCollection<SimpleOrganisationModel> => {
		const list = state.lists[name];

		return {
			data: list?.data.map(id => state.simpleOrganisations[id]) ?? [],
			meta: list?.meta ? (list.meta as Meta) : undefined,
		};
	},

	organisationListDefault: () => organisationGetters.organisationListByName()('default'),

	allOrganizationsList: (state: OrganisationState) => {
		return state.allSimpleOrganizations.map(id => state.simpleOrganisations[id]);
	},
};

export type OrganisationGetters = typeof getters;
export const organisationGetters = getGetters(getters, 'organisation');

export default getters;
