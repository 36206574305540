import { Dictionary, isArray, isObject } from 'lodash';

const makeValidator = <T>(values: T[]) => (value: any) => {
	return values.indexOf(value) !== -1;
};

export function enumValidator<T>(values: T[] | Dictionary<T>) {
	if (isArray(values)) {
		return makeValidator(values);
	} else if (isObject(values)) {
		return makeValidator(Object.values(values));
	} else {
		throw `Provided object should be Array or Object, but is type of ${typeof values}`;
	}
}
