import { RawRuleOf } from '@casl/ability';

import { UserType } from '@/constants/users/constants';

import { AppAbility } from '../ability';
import { ADMINISTRATOR_RULES } from './administrator';
import { PROSTHETIST_RULES } from './prosthetist';

type Rules = RawRuleOf<AppAbility>[];

export const RULES_MAP: Record<UserType, Rules> = {
	administrator: ADMINISTRATOR_RULES,
	prosthetist: PROSTHETIST_RULES,
};
