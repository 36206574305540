import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import { Subject } from '@/common/abilities';
import LayoutAdmin from '@/layouts/LayoutAdmin.vue';
import { extractPage } from '@/router/helpers';

const ViewAdminOrders = () => import('@/views/admin/ViewAdminOrders.vue');

export default createRouteNode({
	shared: {
		basePath: '/orders',
		meta: {
			abilitySubject: Subject.ADMIN_PANEL,
			layout: {
				component: LayoutAdmin,
			},
		},
	},

	routes: [
		{
			name: 'admin-orders',
			path: '/',
			redirect: {
				name: 'admin-orders-list',
				params: { listType: 'new' },
			},
		},
		{
			name: 'admin-orders-list',
			path: '/:listType',
			props: ({ query, params: { listType } }) => ({
				listType,
				page: extractPage(query.page),
			}),
			component: ViewAdminOrders,
		},
	],
});
