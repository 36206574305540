import { pick } from 'lodash';
import { Component, h, VNode } from 'vue';

import { addPopupVModel } from '@/common/popup';
import { Task } from '@/common/task';
import { AnyObject } from '@/tools/types';

import AppConfirmDialog from './components/AppConfirmDialog.vue';
import AppDialog from './components/AppDialog.vue';
import { ManualDialog } from './ManualDialog';

export interface PopupConfig {
	content?: string;
	contentSlot?: (data: { close: () => void }) => VNode | VNode[];
	requireAction?: boolean;
}

export function openDialog(config: PopupConfig = {}) {
	const dialog: ManualDialog = new ManualDialog(
		() => {
			return h(
				AppDialog,
				{
					...pick(config, ['content', 'requireAction']),
					...addPopupVModel(dialog),

					onClose: () => {
						dialog.unregister();
					},
				},
				{
					default: config.contentSlot ? config.contentSlot : () => h('p', config.content ?? ''),
				},
			);
		},
		{ isOpen: true },
	);

	return dialog;
}

export function openAnyDialog(component: Component, props: AnyObject = {}) {
	const task = new Task();
	const dialog = new ManualDialog(component, {
		props: {
			...props,

			onClose: () => {
				task.resolve();
				dialog.unregister();
			},
		},
		isOpen: true,
	});

	return task;
}

export interface PopupConfirmConfig {
	message: string;
	cancelLabel?: string;
	confirmLabel?: string;
	cancelProps?: AnyObject;
	confirmProps?: AnyObject;
}

export function openConfirm(config: PopupConfirmConfig) {
	const task = new Task<boolean>();
	const dialog = new ManualDialog(AppConfirmDialog, {
		props: {
			...config,

			onConfirm: () => {
				task.resolve(true);
			},
			onCancel: () => {
				task.resolve(false);
			},
			onClose: () => {
				dialog.unregister();
			},
		},
		isOpen: true,
	});

	return task;
}
