import { isString } from 'lodash';

import { ValidationRule } from '@/mixins/validation/validator';

export function changeErrorMessage(rule: ValidationRule, newMessage: string): ValidationRule {
	return (...args) => {
		const value = rule(...args);

		return isString(value) ? newMessage : value;
	};
}
