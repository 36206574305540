import { ActionContext, getActions } from '@owlsdepartment/vuex-typed';
import { normalize } from 'normalizr';

import { moduleDecorators } from '@/common/loading';
import { AddressModel } from '@/store/models/Address';
import { ListOf } from '@/store/types';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';

import { AddressGetters } from './getters';
import { AddressMutations } from './mutations';
import { addressListSchema, addressSchema } from './schemas';
import { AddressState } from './state';
import {
	CreateAddressPayload,
	FetchAddressListOfOrganisationPayload,
	FetchAddressListOfUserPayload,
	UpdateAddressPayload,
} from './types';

const { loading } = moduleDecorators('address');

type Ctx = ActionContext<AddressState, AddressGetters, AddressMutations>;

class AddressActions {
	@loading()
	async fetchAddressListOfUser({ commit }: Ctx, { user_id }: FetchAddressListOfUserPayload) {
		const response = await api.get(`/user/${user_id}/address`);

		const name = `of_user_${user_id}`;
		const { entities, result } = normalize(response, addressListSchema);

		commit('addressesFetched', entities as { addresses: ListOf<AddressModel> });
		commit('addressListFetched', { name, data: result });

		return response;
	}

	@loading()
	async fetchAddressListOfOrganisation(
		{ commit }: Ctx,
		{ organisation_id }: FetchAddressListOfOrganisationPayload,
	) {
		const response = await api.get(`/organisation/${organisation_id}/address`);

		const name = `of_organisation_${organisation_id}`;
		const { entities, result } = normalize<any, { addresses: ListOf<AddressModel> }>(
			response,
			addressListSchema,
		);

		if (entities.addresses) {
			commit('addressesFetched', entities);
		}
		commit('addressListFetched', { name, data: result });

		return response;
	}

	@loading({ presets: { id: true } })
	async fetchAddressById({ commit }: Ctx, id: number) {
		const response = await api.get<AddressModel>(`/address/${id}`);

		const { entities } = normalize<any, { addresses: ListOf<AddressModel> }>(
			response,
			addressSchema,
		);

		commit('addressesFetched', entities);

		return response;
	}

	@loading()
	async createAddress(ctx: Ctx, address: CreateAddressPayload) {
		await api.post('/address', address);
	}

	@loading()
	async updateAddress(ctx: Ctx, address: UpdateAddressPayload) {
		await api.put(`/address/${address.address_id}`, address);
	}

	@loading({ presets: { id: true } })
	async deleteAddressById({ commit }: Ctx, id: number): Promise<void> {
		await api.delete(`/address/${id}`);
		commit('addressDeleted', id);
	}
}

const actions = createStoreClassInstance(AddressActions);
export const addressActions = getActions(actions, 'address');

export default actions;
