import { getGetters } from '@owlsdepartment/vuex-typed';

import { ElbowModel } from '@/store/models/Elbow';

import { ElbowState } from './state';

const getters = {
	elbowListByName: (state: ElbowState) => (name: string): Array<ElbowModel> => {
		return state.lists[name]?.map((id: number) => state.elbows[id]) ?? [];
	},
	elbowListByProsthesisId: (state: ElbowState) => (id: number): Array<ElbowModel> => {
		return getters.elbowListByName(state)(`prosthesis_${id}`);
	},
	elbowListDefault: (state: ElbowState) => getters.elbowListByName(state)('default'),
	getElbowById: (state: ElbowState) => (id: number): ElbowModel | undefined => {
		return state.elbows[id];
	},
};

export type ElbowGetters = typeof getters;
export const elbowGetters = getGetters(getters, 'elbow');
export default getters;
