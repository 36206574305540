import { NavigationGuard } from 'vue-router';

import { Action, appAbility } from '@/common/abilities';
import { openToast } from '@/common/popup-systems';

export const checkAbilities: NavigationGuard = (to, from, next) => {
	const { abilitySubject } = to.meta;

	if (!from.name) {
		return next();
	}

	if (abilitySubject && appAbility.cannot(Action.access, abilitySubject)) {
		// When user went from `login` -> `not accessible`
		const nextRoute = from.name === 'login' ? { name: 'home' } : from;

		openToast({
			message: `Insufficient permitions. You were redirected beacuase you don't have access to this url.`,
		});

		return next(nextRoute);
	}

	return next();
};
