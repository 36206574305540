import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import LayoutDefault from '@/layouts/LayoutDefault.vue';

const ViewDraftsList = () => import('@/views/drafts/ViewDraftsList.vue');

export default createRouteNode({
	shared: {
		basePath: '/drafts',
		meta: {
			layout: {
				component: LayoutDefault,
			},
		},
	},

	routes: [
		{
			path: '/',
			name: 'drafts',
			component: ViewDraftsList,
			meta: {
				headline: 'Your Drafts',
			},
		},
		{
			path: '/add-scan',
			name: 'drafts-add-scan',
			component: ViewDraftsList,
			meta: {
				headline: 'Your Drafts',
			},
			props: {
				mode: 'ready-scannable',
			},
		},
	],
});
