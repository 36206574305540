import { Ability, AbilityBuilder, AbilityClass, RawRuleOf } from '@casl/ability';

export function createAppRulesFactory<AppAbility extends Ability>() {
	const AppAbilityClass = Ability as AbilityClass<AppAbility>;

	return (
		defineRules: (
			can: AbilityBuilder<AppAbility>['can'],
			cannot: AbilityBuilder<AppAbility>['cannot'],
		) => void,
	): RawRuleOf<AppAbility>[] => {
		const { can, cannot, rules } = new AbilityBuilder(AppAbilityClass);

		defineRules(can, cannot);

		return rules;
	};
}
