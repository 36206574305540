import axios, { AxiosError } from 'axios';

import { UnprocessableEntityResponse } from '@/store/types';

export type ErrorCallback<E = unknown> = (error: AxiosError<E>) => any;

export interface ErrorHandlers {
	[errorCode: number]: ErrorCallback<any> | undefined;
	422?: ErrorCallback<UnprocessableEntityResponse>;
	noResponse?: ErrorCallback;
	default?: (error: any) => any;
}

export function onApiError<E = any>(error: E, handlers: ErrorHandlers): void {
	let callback: ((err: any) => void) | undefined;

	if (axios.isAxiosError(error)) {
		const responseCode = error.response?.status;

		if (responseCode) {
			callback = handlers[responseCode];
		} else if (error.request) {
			callback = handlers.noResponse;
		}
	}

	if (!callback && 'default' in handlers) {
		callback = handlers.default;
	}

	return callback?.(error);
}
