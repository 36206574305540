import { AxiosError } from 'axios';

import { UnprocessableEntityResponse } from '@/store/types';

const GENERIC_ERROR_MESSAGE =
	'Something went wrong. Please try again later or contact our support team at hello@glazeprosthetics.com';

export function extract422ErrorMessage(error: AxiosError<UnprocessableEntityResponse>): string {
	const { errors = {} } = error.response?.data ?? {};

	return Object.values(errors)?.[0]?.[0] || GENERIC_ERROR_MESSAGE;
}
