import { Dictionary, isArray, isObject } from 'lodash';

type Keys = string[] | Dictionary<string>;

export function areObjectsDifferent(
	first: Dictionary<any>,
	second: Dictionary<any>,
	keys: Keys,
): boolean {
	if (isArray(keys)) {
		return arrayBasedCompare(first, second, keys);
	} else if (isObject(keys)) {
		return objectBasedCompare(first, second, keys);
	} else {
		throw "Wrong 'keys' type.";
	}
}

function arrayBasedCompare(first: Dictionary<any>, second: Dictionary<any>, keys: string[]) {
	for (const key of keys) {
		if (first[key] !== second[key]) return true;
	}

	return false;
}

function objectBasedCompare(
	first: Dictionary<any>,
	second: Dictionary<any>,
	keys: Dictionary<string>,
) {
	for (const [keyLeft, keyRight] of Object.entries(keys)) {
		if (first[keyLeft] !== second[keyRight]) return true;
	}

	return false;
}
