import { ActionContext } from '@owlsdepartment/vuex-typed';

import { createStoreClassInstance } from '@/tools/decorators';
import { to } from '@/tools/helpers';

import mutations from './mutations';
import state from './state';

type Ctx = ActionContext<typeof state, any, typeof mutations>;

class GlobalActions {
	async addGlobalLoader({ commit }: Ctx, cb: () => Promise<any>) {
		commit('setGlobalLoader', true);

		const [err] = await to(cb());

		commit('setGlobalLoader', false);

		if (err) {
			throw err;
		}
	}
}

export default createStoreClassInstance(GlobalActions);
