import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import { Subject } from '@/common/abilities';
import LayoutAdmin from '@/layouts/LayoutAdmin.vue';
import { extractPage } from '@/router/helpers';
import { passParamsToProps } from '@/router/helpers';

const ViewAdminOrganisations = () => import('@/views/admin/ViewAdminOrganisations.vue');
const ViewAdminOrganisationsCreate = () =>
	import('@/views/admin/organisations/ViewAdminOrganisationsCreate.vue');
const ViewAdminOrganisationsDetails = () =>
	import('@/views/admin/organisations/ViewAdminOrganisationsDetails.vue');
const ViewAdminOrganisationsUpdate = () =>
	import('@/views/admin/organisations/ViewAdminOrganisationsUpdate.vue');

export default createRouteNode({
	shared: {
		basePath: '/organisations',
		meta: {
			abilitySubject: Subject.ADMIN_PANEL,
			layout: {
				component: LayoutAdmin,
			},
		},
	},

	routes: [
		{
			name: 'admin-organisations',
			path: '/',
			props: ({ query }) => ({
				page: extractPage(query.page),
			}),
			component: ViewAdminOrganisations,
		},
		{
			name: 'admin-organisations-create',
			path: '/create',
			component: ViewAdminOrganisationsCreate,
		},
		{
			name: 'admin-organisations-details',
			path: '/:organisationId/details',
			component: ViewAdminOrganisationsDetails,
			props: ({ query, params: { organisationId } }) => ({
				organisationId: Number(organisationId),
				page: extractPage(query.page),
			}),
		},
		{
			name: 'admin-organisations-update',
			path: '/:organisationId/edit',
			component: ViewAdminOrganisationsUpdate,
			props: passParamsToProps({ organisationId: Number }),
		},
	],
});
