import { ActionContext } from '@owlsdepartment/vuex-typed';
import { normalize } from 'normalizr';

import { moduleDecorators } from '@/common/loading';
import { SocketModel } from '@/store/models/Socket';
import { ListOf } from '@/store/types';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';

import { SocketGetters } from './getters';
import { SocketMutations } from './mutations';
import { socketList as socketListShema } from './schemas';
import { SocketState } from './state';
import { FetchSocketListPayload } from './types';

const { loading } = moduleDecorators('socket');

type Ctx = ActionContext<SocketState, SocketGetters, SocketMutations>;

class SocketActions {
	@loading()
	async fetchSocketList({ commit }: Ctx, { prosthesis_id }: FetchSocketListPayload) {
		const response = await api.get<SocketModel[]>(`/bionic-hands`);

		const name = `prosthesis_${prosthesis_id}`;
		const { entities, result } = normalize(response, socketListShema);

		commit('socketsFetched', entities as { sockets: ListOf<SocketModel> });
		commit('socketListFetched', { name, data: result });

		return response;
	}

	@loading({ presets: { id: true } })
	async fetchSocket({ commit }: Ctx, id: number) {
		const response = await api.get<SocketModel>(`/bionic-hands/${id}`);

		commit('socketFetched', response);

		return response;
	}
}

export default createStoreClassInstance(SocketActions);
