import { Cookies } from '@/common/cookies';
import { COOKIES_AUTHORIZATION } from '@/constants/cookies/constants';

const state = () => {
	const { access_token, expires_at, token_type } = (Cookies.getJSON(COOKIES_AUTHORIZATION) ??
		{}) as {
		access_token?: string;
		expires_at?: number;
		token_type?: string;
	};

	return {
		accessToken: access_token,
		tokenType: token_type,
		expiresAt: expires_at ? new Date(expires_at) : null,
		currentUserId: null as null | number,
	};
};

export default state;
