<template>
	<div :class="className">
		<div v-if="img" :class="`${className}__img`" />

		<div :class="`${className}__content`">
			<div :class="`${className}__title`" />
			<div :class="`${className}__subtitle`" />
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		img: {
			type: Boolean,
			default: false,
		},
	},

	setup() {
		return {
			className: 'vue-content-placeholders-heading',
		};
	},
});
</script>
