import { ActionContext, getActions } from '@owlsdepartment/vuex-typed';
import { normalize } from 'normalizr';

import { moduleDecorators } from '@/common/loading';
import { OrganisationModel, SimpleOrganisationModel } from '@/store/models/Organisation';
import { ApiCollection, ListOf } from '@/store/types';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';
import { applyQuery } from '@/tools/helpers';

import { OrganisationGetters } from './getters';
import { OrganisationMutations } from './mutations';
import { organisationListSchema, organisationSchema, pagedOrganisationListSchema } from './schemas';
import { OrganisationState } from './state';
import {
	CreateOrganisationPayload,
	FetchOrganisationListPayload,
	UpdateOrganisationPayload,
} from './types';

const { loading } = moduleDecorators('organisation');

const defaultPerPage = 12;

type Ctx = ActionContext<OrganisationState, OrganisationGetters, OrganisationMutations>;

class OrganisationActions {
	@loading({ presets: { id: true } })
	async fetchOrganisationById({ commit }: Ctx, id: number) {
		const response = await api.get<OrganisationModel>(`/organisation/${id}`);

		const { entities } = normalize<any, { organisations: ListOf<OrganisationModel> }>(
			response,
			organisationSchema,
		);

		commit('organisationsFetched', entities);

		return response;
	}

	@loading()
	async fetchAllOrganisations({ commit }: Ctx) {
		const response = await api.get<SimpleOrganisationModel[]>(`/organisations`);
		const { entities, result } = normalize<
			any,
			{ organisations: ListOf<SimpleOrganisationModel> },
			number[]
		>(response, organisationListSchema);

		commit('simpleOrganisationsFetched', entities);
		commit('allSimpleOrganisationsFetched', result);

		return response;
	}

	@loading()
	async fetchOrganisationList(
		{ commit }: Ctx,
		{ page, perPage = defaultPerPage }: FetchOrganisationListPayload,
	) {
		const name = `default`;

		if (!page) {
			commit('organisationListReset', { name });
			page = 1;
		}

		const url = applyQuery(`/organisation`, { page, per_page: perPage });
		const response = await api.get<ApiCollection<SimpleOrganisationModel>>(url);
		const { entities, result } = normalize<any, { organisations: ListOf<SimpleOrganisationModel> }>(
			response,
			pagedOrganisationListSchema,
		);

		commit('simpleOrganisationsFetched', entities);
		commit('organisationListFetched', { name, data: result });

		return response;
	}

	@loading()
	async createOrganisation(ctx: Ctx, organisation: CreateOrganisationPayload) {
		await api.post('/organisation', organisation);
	}

	@loading()
	async updateOrganisation(ctx: Ctx, organisation: UpdateOrganisationPayload) {
		await api.put(`/organisation/${organisation.id}`, organisation);
	}

	@loading({ presets: { id: true } })
	async deleteOrganisationById({ commit }: Ctx, id: number): Promise<void> {
		await api.delete(`/organisation/${id}`);
		commit('organisationDeleted', id);
	}
}

const actions = createStoreClassInstance(OrganisationActions);
export const organisationActions = getActions(actions, 'organisation');

export default actions;
