import { schema } from 'normalizr';

export const addressSchema = new schema.Entity(
	'addresses',
	{},
	{
		idAttribute: 'address_id',
	},
);

export const addressListSchema = new schema.Array(addressSchema);
