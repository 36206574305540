import { createRoot } from '@owlsdepartment/vue-tree-routes';

import admin from './admin';
import base from './base';
import dashboard from './dashboard';
import drafts from './drafts';
import login from './login';
import order from './order';
import settings from './settings';
import termsAndConditions from './termsAndConditions';

const routes = [admin, dashboard, drafts, login, order, settings, termsAndConditions];

/* base routes has to be last */
routes.push(base);

export default [...createRoot(routes)];
