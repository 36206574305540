import { defineMutations } from '@owlsdepartment/vuex-typed';

import { SocketModel } from '@/store/models/Socket';
import { ListFetched, ListOf } from '@/store/types';

import state from './state';

const mutations = defineMutations(state, {
	socketsFetched(state, { sockets = {} }: { sockets: ListOf<SocketModel> }) {
		Object.values(sockets).forEach(item => (state.sockets[item.id] = item));
	},

	socketListFetched(state, { name, data }: ListFetched) {
		state.lists[name] = data;
	},

	socketListReset(state, { name }: { name: string }) {
		state.lists[name] = [];
	},

	socketFetched(state, socket: SocketModel) {
		state.sockets[socket.id] = socket;
	},
});

export type SocketMutations = typeof mutations;
export default mutations;
