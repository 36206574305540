import { NavigationGuard } from 'vue-router';

import { authActions, authGetters } from '@/store/modules/auth';
import { to as awaitTo } from '@/tools/helpers';

export const requireAuth: NavigationGuard = async (to, from, next) => {
	const isAuthorized = authGetters.isAuthorized();
	const isUserFetched = isAuthorized && !!authGetters.currentUser();
	const requireNoAuth = !!to.meta.requireNoAuth;

	if (to.name === '404') {
		if (!isUserFetched) {
			await awaitTo(authActions.fetchCurrentUser());
		}

		return next();
	}

	if (requireNoAuth && isUserFetched) {
		return next({ name: 'home' });
	} else if (!requireNoAuth && !isUserFetched) {
		try {
			await authActions.fetchCurrentUser();

			return next();
		} catch {
			return next({ name: 'login' });
		}
	}

	return next();
};
