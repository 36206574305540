import { getMutations } from '@owlsdepartment/vuex-typed';

import { SkinGloveModel } from '@/store/models/SkinGlove';
import { ListFetched, ListOf } from '@/store/types';

import { SkinGloveState } from './state';

const mutations = {
	skinGlovesFetched(state: SkinGloveState, { skinGloves }: { skinGloves: ListOf<SkinGloveModel> }) {
		Object.values(skinGloves).forEach(item => (state.skinGloves[item.id] = item));
	},

	skinGloveFetched(state: SkinGloveState, { skinGlove }: { skinGlove: SkinGloveModel }) {
		state.skinGloves[skinGlove.id] = skinGlove;
	},

	skinGloveListFetched(state: SkinGloveState, { name, data }: ListFetched) {
		state.lists[name] = data;
	},

	skinGloveListReset(state: SkinGloveState, { name }: { name: string }) {
		state.lists[name] = [];
	},
};

export type SkinGloveMutations = typeof mutations;
export const {
	skinGlovesFetched,
	skinGloveFetched,
	skinGloveListFetched,
	skinGloveListReset,
} = getMutations(mutations, 'skinGlove');

export default mutations;
