import { getMutations } from '@owlsdepartment/vuex-typed';

import { AddressModel } from '@/store/models/Address';
import { ListFetched, ListOf } from '@/store/types';

import { AddressState } from './state';

const mutations = {
	addressesFetched(state: AddressState, { addresses }: { addresses: ListOf<AddressModel> }) {
		Object.values(addresses).forEach(item => (state.addresses[item.address_id] = item));
	},

	addressListFetched(state: AddressState, { name, data }: ListFetched) {
		state.lists[name] = data;
	},

	addressListReset(state: AddressState, { name }: { name: string }) {
		state.lists[name] = [];
	},

	addressDeleted(state: AddressState, id: number) {
		delete state.addresses[id];
	},
};

export type AddressMutations = typeof mutations;
export const addressMutations = getMutations(mutations, 'address');

export default mutations;
