import { isNumber } from 'lodash';

import { ValidationRule } from '@/mixins/validation/validator';

export const noZeroValue: ValidationRule = value => {
	if (!isNumber(value)) {
		return true;
	}

	const isValid = value !== 0;

	return isValid || `Please provide a nonzero value`;
};
