import { getState } from '@owlsdepartment/vuex-typed';

import { UserModel } from '@/store/models/User';
import { ListOf, NormalizedCollection } from '@/store/types';

const state = {
	users: {} as ListOf<UserModel>,
	lists: {} as ListOf<NormalizedCollection>,
};

export type UserState = typeof state;
export const userState = getState(state, 'user');

export default state;
