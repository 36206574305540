import { getState } from '@owlsdepartment/vuex-typed';

import { ColorPatternModel } from '@/store/models/ColorPattern';
import { ListOf } from '@/store/types';

const state = () => ({
	colorPatterns: {} as ListOf<ColorPatternModel>,
	lists: {} as ListOf<Array<number>>,
});

export type ColorPatternState = ReturnType<typeof state>;
export const { colorPatterns, lists } = getState(state, 'colorPattern');

export default state;
