import { markRaw, Ref, ref } from 'vue';

import { ManualPopup } from './ManualPopup';
import { Popup } from './Popup';

export class PopupSystem<P extends Popup = Popup, MP extends ManualPopup = ManualPopup> {
	static systemId = 'popup-system';

	protected _popups: Ref<P[]> = ref([]);
	protected _manualPopups: Ref<MP[]> = ref([]);

	get popups(): P[] {
		return this._popups.value;
	}

	get manualPopups(): MP[] {
		return this._manualPopups.value;
	}

	static get instance(): PopupSystem {
		throw Error('[PopupInstance] `getInstance` needs to be overriden');
	}

	register(popup: P) {
		// IMPORTANT: Reactive array will also convert child elements to reactive
		// so we need to mark them as raw to prevent it
		this.popups.push(markRaw(popup));
	}

	unregister(popup: P) {
		const index = this.popups.findIndex(p => p === popup);

		if (index !== -1) {
			this.popups.splice(index, 1);
		}
	}

	registerManual(manualPopup: MP) {
		this.manualPopups.push(markRaw(manualPopup));
	}

	unregisterManual(manualPopup: MP) {
		const index = this.manualPopups.findIndex(p => p === manualPopup);

		if (index !== -1) {
			this.manualPopups.splice(index, 1);
		}
	}
}
