import { createRouteNode } from '@owlsdepartment/vue-tree-routes';
import { RouteMeta } from 'vue-router';

import { OrderStep } from '@/constants/order/constants';
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import LayoutOrder from '@/layouts/LayoutOrder.vue';
import { preloadOrder, resolveRouteId } from '@/router/helpers';
import { orderCustomizationDynamicRedirect } from '@/router/helpers';

const ViewOrderNew = () => import('@/views/order/ViewOrderNew.vue');
const ViewOrderTerminalCustomization = () =>
	import('@/views/order/ViewOrderTerminalCustomization.vue');
const ViewOrderSocketCustomization = () => import('@/views/order/ViewOrderSocketCustomization.vue');
const ViewOrderColorPatternCustomization = () =>
	import('@/views/order/ViewOrderColorPatternCustomization.vue');
const ViewOrderSkinGloveCustomization = () =>
	import('@/views/order/ViewOrderSkinGloveCustomization.vue');
const ViewOrderElbowCustomization = () => import('@/views/order/ViewOrderElbowCustomization.vue');
const ViewOrderClientsDetails = () => import('@/views/order/ViewOrderClientsDetails.vue');
const ViewOrderPreview = () => import('@/views/order/ViewOrderPreview.vue');
const ViewOrderAddingScan = () => import('@/views/order/ViewOrderAddingScan.vue');
const ViewOrderAlignment = () => import('@/views/order/ViewOrderAlignment.vue');
const ViewOrderFiles = () => import('@/views/order/ViewOrderFiles.vue');
const ViewOrderShippingAddress = () => import('@/views/order/ViewOrderShippingAddress.vue');
const ViewOrderShippingAddressNew = () => import('@/views/order/ViewOrderShippingAddressNew.vue');
const ViewOrderFinalise = () => import('@/views/order/ViewOrderFinalise.vue');
const ViewExtraParameters = () => import('@/views/order/ViewExtraParameters.vue');

const meta: RouteMeta = {
	layout: {
		component: LayoutOrder,
		props: {
			resolveOrderGuid: resolveRouteId('orderGuid'),
		},
	},
};

export default createRouteNode({
	shared: {
		basePath: '/order',
		meta,
	},

	routes: [
		{
			path: '/',
			name: 'order',
			redirect: { name: OrderStep.New },
		},
		{
			path: '/new',
			name: OrderStep.New,
			component: ViewOrderNew,
		},
		{
			path: '/:orderGuid/customization',
			name: 'order-customization',
			beforeEnter: orderCustomizationDynamicRedirect,
		} as any,
		{
			path: '/:orderGuid/customization/terminal',
			name: OrderStep.Terminal,
			component: ViewOrderTerminalCustomization,
			props: true,
			beforeEnter: preloadOrder,
		},
		{
			path: '/:orderGuid/customization/socket',
			name: OrderStep.Socket,
			component: ViewOrderSocketCustomization,
			props: true,
			beforeEnter: preloadOrder,
		},
		{
			path: '/:orderGuid/customization/extra-parameters',
			name: OrderStep.ExtraParameters,
			component: ViewExtraParameters,
			props: true,
			beforeEnter: preloadOrder,
		},
		{
			path: '/:orderGuid/customization/color-pattern',
			name: OrderStep.ColorPattern,
			component: ViewOrderColorPatternCustomization,
			props: true,
			beforeEnter: preloadOrder,
		},
		{
			path: '/:orderGuid/customization/skin-glove',
			name: OrderStep.SkinGlove,
			component: ViewOrderSkinGloveCustomization,
			props: true,
			beforeEnter: preloadOrder,
		},
		{
			path: '/:orderGuid/customization/elbow',
			name: OrderStep.Elbow,
			component: ViewOrderElbowCustomization,
			props: true,
			beforeEnter: preloadOrder,
		},
		{
			path: '/:orderGuid/clients-details',
			name: OrderStep.ClientDetails,
			component: ViewOrderClientsDetails,
			beforeEnter: preloadOrder,
			props: true,
		},
		{
			path: '/:orderGuid/preview',
			name: OrderStep.Preview,
			component: ViewOrderPreview,
			props: true,
			beforeEnter: preloadOrder,
		},
		{
			path: '/:orderGuid/scan',
			name: OrderStep.AddingScan,
			component: ViewOrderAddingScan,
			props: true,
			beforeEnter: preloadOrder,
			meta: {
				...meta,
				flow: 'prosthetist',
				headline: orderNumber => `Adding scan to order #${orderNumber}`,
			},
		},
		{
			path: '/:orderGuid/alignment',
			name: OrderStep.Alignment,
			component: ViewOrderAlignment,
			props: true,
			beforeEnter: preloadOrder,
			meta: {
				...meta,
				flow: 'prosthetist',
				headline: orderNumber => `Adding scan to order #${orderNumber}`,
			},
		},
		{
			path: '/:orderGuid/files',
			name: OrderStep.Files,
			component: ViewOrderFiles,
			props: true,
			beforeEnter: preloadOrder,
			meta: {
				...meta,
				flow: 'prosthetist',
				headline: orderNumber => `Adding scan to order #${orderNumber}`,
			},
		},
		{
			path: '/:orderGuid/shipping-address',
			name: OrderStep.ShippingAddress,
			component: ViewOrderShippingAddress,
			props: true,
			beforeEnter: preloadOrder,
			meta: {
				...meta,
				flow: 'prosthetist',
				headline: orderNumber => `Adding scan to order #${orderNumber}`,
			},
		},
		{
			path: '/:orderGuid/shipping-address/new',
			name: 'order-shipping-address-new',
			component: ViewOrderShippingAddressNew,
			props: true,
			meta: {
				...meta,
				layout: {
					component: LayoutDefault,
				},
			},
		},
		{
			path: '/:orderGuid/finalise',
			name: OrderStep.Finalise,
			component: ViewOrderFinalise,
			props: true,
			beforeEnter: preloadOrder,
			meta: {
				...meta,
				flow: 'prosthetist',
				headline: orderNumber => `Adding scan to order #${orderNumber}`,
			},
		},
	],
});
