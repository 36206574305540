import gsap from 'gsap';

import { VueTransitionVBind } from '@/tools/types';

export const landingTransition: VueTransitionVBind = {
	mode: 'out-in',
	css: false,

	onEnter: (el, done) => {
		gsap.fromTo(
			el,
			{ y: 20, autoAlpha: 0 },
			{ y: 0, autoAlpha: 1, duration: 0.7, ease: 'power4.out', onComplete: done },
		);
	},

	onLeave: (el, done) => {
		gsap.to(el, { y: -10, autoAlpha: 0, duration: 0.2, ease: 'power2.out', onComplete: done });
	},
};
