import { Dictionary, isFunction } from 'lodash';
import { RouteLocationNormalized } from 'vue-router';

type RoutePropsFunction = (to: RouteLocationNormalized) => Record<string, any>;

function passParamsToProps(
	config: Dictionary<(val: any) => any>,
	props: Dictionary<any> = {},
): RoutePropsFunction {
	return ({ params }) => {
		for (const [key, val] of Object.entries(config)) {
			if (isFunction(val)) {
				props[key] = val(params[key]);
			}
		}

		return props;
	};
}

export default passParamsToProps;
