import { ActionContext } from '@owlsdepartment/vuex-typed';

import { moduleDecorators } from '@/common/loading';
import { ERRORS } from '@/constants/errors/constants';
import { CurrentUserSettingsModel, UserModel } from '@/store/models/User';
import api from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';

import getters from './getters';
import mutations from './mutations';
import state from './state';
import { LoginWithCredentialsPayload, RemindPasswordPayload, ResetPasswordPayload } from './types';

const { loading } = moduleDecorators('auth');

type Ctx = ActionContext<typeof state, typeof getters, typeof mutations>;

class AuthActions {
	@loading()
	async loginWithCredentials({ commit }: Ctx, { data }: LoginWithCredentialsPayload) {
		const result = await api.post('/auth/login', data, { suppressToken: true });

		if (
			typeof result?.access_token !== 'string' ||
			typeof result?.token_type !== 'string' ||
			typeof result?.expires_in !== 'number'
		) {
			throw Error(ERRORS.GENERIC_ERROR_MESSAGE);
		}

		commit('setAuthorization', result);

		return result;
	}

	@loading()
	async remindPassword(ctx: Ctx, data: RemindPasswordPayload) {
		return await api.post('/auth/forgot-password', data, { suppressToken: true });
	}

	@loading()
	async resetPassword(ctx: Ctx, data: ResetPasswordPayload) {
		return await api.post('/auth/reset-password/', data, { suppressToken: true });
	}

	@loading()
	async fetchCurrentUser({ commit }: Ctx) {
		const result = await api.get<UserModel>('/auth/me');

		commit('user/userFetched', result, { root: true });
		commit('setCurrentUserId', result.id);

		return result;
	}

	@loading()
	async updateAuthenticatedUser(ctx: Ctx, data: CurrentUserSettingsModel) {
		const result = await api.put<CurrentUserSettingsModel>('/auth/me', data);

		return result;
	}

	@loading()
	async refreshToken({ commit }: Ctx) {
		const result = await api.get('/auth/refresh-token', { suppressToken: true });

		if (
			typeof result?.access_token !== 'string' ||
			typeof result?.token_type !== 'string' ||
			typeof result?.expires_in !== 'number'
		) {
			throw Error('Token expired');
		}

		commit('setAuthorization', result);

		return result;
	}

	@loading()
	async logout({ commit }: Ctx) {
		await api.get('/auth/logout');

		commit('resetAuthorization');
	}

	passiveLogout({ commit }: Ctx) {
		commit('resetAuthorization');
	}
}

export default createStoreClassInstance(AuthActions);
