import { computed, onMounted, ref } from 'vue';

import { remToPx } from '@/tools/helpers';

const vh = ref(0);
const vw = ref(0);
const rem = ref(10);
let initialized = false;

function initViewUnitsSystem() {
	const updateViewUnits = () => {
		vh.value = window.innerHeight * 0.01;
		vw.value = window.innerWidth * 0.01;
		rem.value = remToPx(1);

		document.documentElement.style.setProperty('--vh', `${vh.value}px`);
	};

	onMounted(() => {
		updateViewUnits();
		window.addEventListener('resize', updateViewUnits);
	});

	initialized = true;
}

export function useViewUnits() {
	if (!initialized) {
		initViewUnitsSystem();
	}

	return {
		windowHeight: computed(() => vh.value * 100),
		windowWidth: computed(() => vw.value * 100),
		vh: computed(() => vh.value),
		vw: computed(() => vw.value),

		remToPx: computed(() => (val: number) => val * rem.value),
	};
}
