import { defineGetters } from '@owlsdepartment/vuex-typed';

import { ProsthesisModel } from '@/store/models/Prosthesis';
import { ProsthesisCategory } from '@/store/models/ProsthesisCategory';

import state from './state';

const getters = defineGetters(state, {
	prosthesisListByName: state => (name: string): Array<ProsthesisModel> => {
		return state.lists[name]?.map((id: number) => state.prosthesis[id]) ?? [];
	},

	prosthesisListDefault: (state, getters): ProsthesisModel[] =>
		getters.prosthesisListByName('default'),

	getProsthesisById: state => (id: number): ProsthesisModel | undefined => {
		return state.prosthesis[id];
	},

	prosthesisCategoriesListByName: state => (name: string): Array<ProsthesisCategory> => {
		return state.categoriesLists[name]?.map((id: number) => state.prosthesisCategories[id]) ?? [];
	},

	prosthesisCategoriesListDefault: (state, getters): ProsthesisCategory[] =>
		getters.prosthesisCategoriesListByName('default'),

	getProsthesisCategoryById: state => (id: number): ProsthesisCategory | undefined => {
		return state.prosthesisCategories[id];
	},
});

export type ProsthesisGetters = typeof getters;
export default getters;
