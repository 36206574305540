import { schema, SchemaArray } from 'normalizr';

import { OrderModel } from '@/store/models/Order';

export const orderSchema = new schema.Entity<OrderModel>('orders', {}, { idAttribute: 'guid' });

export const orderListSchema = new schema.Array(orderSchema);

export const pagedOrderListSchema = new schema.Object<SchemaArray<OrderModel>>({
	data: orderListSchema,
});
