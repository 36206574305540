import { defineModule } from '@owlsdepartment/vuex-typed';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

export default defineModule({
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
});
