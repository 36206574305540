import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import LayoutDefault from '@/layouts/LayoutDefault.vue';

const ViewSettings = () => import('@/views/settings/ViewUserSettings.vue');

export default createRouteNode({
	shared: {
		basePath: '/settings',
		meta: {
			layout: {
				component: LayoutDefault,
				props: {},
			},
		},
	},

	routes: [
		{
			path: '/',
			name: 'settings',
			component: ViewSettings,
		},
	],
});
