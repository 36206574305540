import { gsap, Power4 } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

let isScrolling = false;

export function scrollToError(itemClass = '.form-item--has-error') {
	if (isScrolling) return;

	isScrolling = true;

	const itemEl = document.querySelector(itemClass);

	if (!itemEl) {
		isScrolling = false;
		return;
	}

	const headerHeight = document.querySelector('.app-header')?.getBoundingClientRect().height ?? 0;

	gsap.to(window, {
		duration: 0.4,
		ease: Power4.easeOut,
		scrollTo: {
			y: itemEl,
			offsetY: headerHeight + 20,
		},
		onComplete: () => {
			isScrolling = false;
		},
	});
}
