import { Plugin } from 'vue';

import { openToast } from './openToast';

declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$toast: {
			open: typeof openToast;
		};
	}
}

export const toastPlugin: Plugin = app => {
	app.config.globalProperties.$toast = {
		open: openToast,
	};
};
