import { defineMutations } from '@owlsdepartment/vuex-typed';
import moment from 'moment';

import { Cookies } from '@/common/cookies';
import { COOKIES_AUTHORIZATION } from '@/constants/cookies/constants';

import state from './state';

const mutations = defineMutations(state, {
	setAuthorization(
		state,
		{
			access_token,
			token_type,
			expires_in,
		}: { access_token: string; token_type: string; expires_in: number },
	) {
		const expiresAt = moment()
			.add(expires_in - 30, 'seconds')
			.toDate();

		state.accessToken = access_token;
		state.tokenType = token_type;
		state.expiresAt = expiresAt;

		Cookies.set(
			COOKIES_AUTHORIZATION,
			{ access_token, expires_at: expiresAt, token_type },
			{
				sameSite: 'strict',
				expires: expiresAt,
			},
		);
	},

	resetAuthorization(state) {
		state.accessToken = undefined;
		state.tokenType = undefined;
		state.expiresAt = null;
		state.currentUserId = null;

		Cookies.remove(COOKIES_AUTHORIZATION);
	},

	setCurrentUserId(state, id: number | null) {
		state.currentUserId = id;
	},
});

export default mutations;
