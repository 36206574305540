import { getMutations } from '@owlsdepartment/vuex-typed';

import { NotificationModel } from '@/store/models/Notification';
import { ListFetched, ListOf } from '@/store/types';

import { NotificationState } from './state';

const mutations = {
	notificationFetched(
		state: NotificationState,
		{ notification }: { notification: NotificationModel },
	) {
		state.notifications[notification.id] = notification;
	},

	notificationsFetched(
		state: NotificationState,
		{ notifications }: { notifications: ListOf<NotificationModel> },
	) {
		for (const k in notifications) {
			state.notifications[notifications[k].id] = notifications[k];
		}
	},

	notificationListFetched(state: NotificationState, { name, data }: ListFetched<string>) {
		state.lists[name] = data;
	},

	notificationListReset(state: NotificationState, { name }: { name: string }) {
		state.lists[name] = [];
	},
};

export type NotificationMutations = typeof mutations;
export const notificationMutations = getMutations(mutations, 'notification');

export default mutations;
