<template>
	<div class="heading">
		<h1>Terms and Conditions</h1>
		<h2>Version applicable from 01/01/2024</h2>
	</div>
</template>

<style lang="scss">
.heading {
	padding: 2rem 0;
	border-bottom: 1px solid $color-primary;
	h1 {
		@include heading-3;
	}
	h2 {
		@include heading-4;
		padding: 1.5rem 0;
	}
}
</style>
