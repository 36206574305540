import { Plugin } from 'vue';

import { AppAbility, appAbility } from './ability';
import { canOneOf } from './canOneOf';
import { ActionType, SubjectType } from './data';

export const aclPlugin: Plugin = app => {
	app.config.globalProperties.$cannot = appAbility.cannot;

	app.config.globalProperties.$canOneOf = canOneOf;
};

declare module 'vue' {
	interface ComponentCustomProperties {
		$ability: AppAbility;
		$can(this: this, ...args: Parameters<this['$ability']['can']>): boolean;
		$cannot(this: this, ...args: Parameters<this['$ability']['cannot']>): boolean;

		$canOneOf(action: ActionType, subjects: SubjectType[]): boolean;
	}
}
